// src/App.js

import { PubSubProvider } from './PubSubContext'; // Update the import
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import FileUpload from './FileUpload';
//import JsonDashboard from './JsonDashboard';


/*
{
        "chartType": "scatter",
        "app": {
          "width": 300,
          "height": 300,
        },
        "data": {
          "data_source": "nfl_combined",
          "aggregation_keys": ["Playing Team"],
          "aggregations": [
            {"type": "sum", "field": "Total Yards Offense", "rename": "Total Yards Offense"},
						{"type": "sum", "field": "Total Yards Defense", "rename": "Total Yards Defense"}
          ],
          "filters": [],
          "columns_to_use": ["Total Yards Offense", "Total Yards Defense"],
          "labels": ["Playing Team"]
        },
        "channels": [
          {"pubsub": "publish", "channel": "teamChannel", "field": "Playing Team"}
        ]
    },
    {
      "chartType": "bar",
        "keyColumn": "Playing Team",
        "app": {
          "width": 300,
          "height": 300,
        },
        "data": {
					"data_source": "nfl_combined",
					"aggregation_keys": ["Playing Team"],
          "secondary_key": "Week",
          "aggregations": [
            {"type": "sum", "field": "Total Yards Offense", "rename": "Total Yards Offense"},
						{"type": "sum", "field": "Total Yards Defense", "rename": "Total Yards Defense"}
          ],
					"filters": [],
					"columns_to_use": ["Total Yards Offense", "Total Yards Defense"],
          "data_from_columns": ["Total Yards Offense", "Total Yards Defense"],
					"labels": ["Team Points"]
				},
        "channels": [
          {"pubsub": "subscribe", "channel": "teamChannel", "field": "Playing Team"}
        ]
        
  },
  {
    "chartType": "line",
      "keyColumn": "Playing Team",
      "app": {
        "width": 300,
        "height": 300,
        'orientation': 'vertical'
        //"inputMax": 1000
      },
      "data": {
        "data_source": "nfl_combined",
        "aggregation_keys": [],
        "aggregations": [
        ],
        "filters": [],
        "data_from_rows": {"key": "Week", "values": ["Total Yards Offense"]},
        "labels": ["Week"]
      },
      "channels": [
        {"pubsub": "subscribe", "channel": "teamChannel", "field": "Playing Team"}
      ]
      
},
    {
        "chartType": "table",
        "app": {
          "width": 300,
          "height": 300,
        },  
        "data": {
          "data_source": "nfl_combined",
          "aggregation_keys": [],
          "aggregations": [],
          "filters": [],
          "columns_to_use": [
              "Playing Team",
              "Opponent",
              "Week",
              "Total Yards Offense",
              "Total Yards Defense",
          ],
          "labels": []
        },
        "channels": [
          {"pubsub": "subscribe", "channel": "teamChannel", "field": "Playing Team"}
        ],
        "options": {
            "backgroundColors": {
              "gradient": ["#ff0000", "#ffff00", "#00ff00"],
              "globalMinMax": false,
              "columnConfigs": [
                {
                  "column": "Playing Team",
                  "disableGradient": true,
                },
                {
                  "column": "Opponent",
                  "disableGradient": true,
                },
                {
                  "column": "Week",
                  "disableGradient": true,
                },
                {
                  "column": "Total Yards Offense",
                  "globalColumnMinMax": true
                },
                {
                  "column": "Total Yards Defense",
                  "globalColumnMinMax": true,
                  "reverseGradient": true
                }
              ]
            }
        }
    },
    {
      "chartType": "label",
      "channels": [
        {"pubsub": "subscribe", "channel": "teamChannel", "field": "Playing Team"}
      ],
      "columns_to_include": ["Playing Team"],
      "keyColumn": "Playing Team",
      "app": {
      },
      "data": {
        "data_source": "nfl_combined",
        "aggregation_keys": [],
        "aggregations": [],
        "filters": [],
        "columns_to_use": ["Playing Team"],
        "labels": ["Team Points"]
      },
    },
    {
      "chartType": "table",
      "app": {
        "width": 300,
        "height": 300,
      },  
      "data": {
        "data_source": "ticker",
        "aggregation_keys": [],
        "aggregations": [],
        "filters": [],
        "columns_to_use": [
            "ticker",
            "name",
            "market_cap",
            "total_employees",
            "Total Revenue",
            "Cost of Profit",
            "Gross Profit",
            "GICS Sector"
        ],
        "labels": []
      },
      "channels": [
        
      ],
      "options": {
        "backgroundColors": {
          "gradient": ["#ff0000", "#ffff00", "#00ff00"],
          "globalMinMax": false,
          "columnConfigs": [
            {
              "column": "ticker",
              "disableGradient": true,
            },
            {
              "column": "name",
              "disableGradient": true,
            },
            {
              "column": "market_cap",
              "disableGradient": false,
            },
            {
              "column": "total_employees",
              "disableGradient": false,
            },
            {
              "column": "Total Revenue",
              "disableGradient": false,
            },
            {
              "column": "GICS Sector",
              "disableGradient": true,
            },
          ]
        }
      }
  },
  {
    "chartType": "scatter",
    "app": {
      "width": 300,
      "height": 300,
    },
    "data": {
      "data_source": "ticker",
      "aggregation_keys": [],
      "aggregations": [
      ],
      "filters": [],
      "columns_to_use": ["Total Revenue", "Gross Profit"],
      "labels": ["Ticker"]
    },
    "channels": [
    ]
  },
  {
    "chartType": "memory_usage",
    "app": {
      "width": 300,
      "height": 300,
    },
    "data": {
    }
  },
  */


function App() {
  const rawDataTest = [];

  const configs = [

    {
      "chartType": "table",
      "app": {
        "width": 300,
        "height": 300,
      },  
      "data": {
        "data_source": "ticker",
        "aggregation_keys": [],
        "aggregations": [],
        "filters": [],
        "columns_to_use": [
            "ticker",
            "name",
            "market_cap",
            "total_employees",
            "Total Revenue",
            "Cost of Profit",
            "Gross Profit",
            "GICS Sector",
            "GICS Sub-Industry"
        ],
        "labels": []
      },
      "channels": [
        {"pubsub": "subscribe", "channel": "tickerSector", "field": "GICS Sector"},
        {"pubsub": "subscribe", "channel": "tickerSymbol", "field": "Ticker"}
      ],
      "options": {
        "fontSize": "0.7em",
        "backgroundColors": {
          "gradient": ["#ff0000", "#ffff00", "#00ff00"],
          "globalMinMax": false,
          "columnConfigs": [
            {
              "column": "ticker",
              "disableGradient": true,
            },
            {
              "column": "name",
              "disableGradient": true,
            },
            {
              "column": "market_cap",
              "disableGradient": false,
              "convertToMagnitude": true,
              "inputValueInThousands": false,
            },
            {
              "column": "total_employees",
              "disableGradient": false,
            },
            {
              "column": "Total Revenue",
              "disableGradient": false,
              "convertToMagnitude": true,
            },
            {
              "column": "Cost of Profit",
              "disableGradient": false,
              "convertToMagnitude": true,
            },
            {
              "column": "Gross Profit",
              "disableGradient": false,
              "convertToMagnitude": true,
            },
            {
              "column": "GICS Sector",
              "disableGradient": true,
            },
          ]
        }
      }
  },
  {
    "chartType": "dropdown",
    "app": {
      "width": 300,
      "height": 300,
    },
    "data": {
      "data_source": "ticker",
      "aggregation_keys": [],
      "aggregations": [
      ],
      "filters": [],
      "columns_to_use": ["Total Revenue", "Gross Profit"],
      "labels": ["Ticker"],
      "columnName": "GICS Sector",
      "label": "Sector"
    },
    "channels": [
      {"pubsub": "publish", "channel": "tickerSector", "field": "GICS Sector"}
    ]
  },
  {
    "chartType": "scatter",
    "app": {
      "width": 301,
      "height": 300,
    },
    "data": {
      "data_source": "ticker",
      "aggregation_keys": [],
      "aggregations": [
      ],
      "filters": [],
      "columns_to_use": ["Total Revenue", "Gross Profit"],
      "labels": ["Ticker"],
      "title": "Revenue vs Profit",
      
    },
    "channels": [
      {"pubsub": "publish", "channel": "tickerSymbol", "field": "Ticker"},
      {"pubsub": "subscribe", "channel": "tickerSector", "field": "GICS Sector"},
    ]
  },
  //npm run build:production
  //git add .
  //git commit -m "whatever"
  //git subtree push --prefix build production deployment-branch
  //scp ~/github/expressjs/json/x21.json root@143.244.190.217:/var/www/charts_configs
  //scp ~/github/expressjs/business.csv root@143.244.190.217:/var/www/charts_data
  //meta password: ~'4%at~Le:JbXSD


  

    //... add more configs
];
const emptyConfigs1 = [
  {
    "chartType": "scatter",
	  "chartId": "1",
    "app": {
      "width": 300,
      "height": 300,
    },
    "data": {
      "data_source": "nba",
      "aggregation_keys": [],
      "aggregations": [
      ],
      "filters": [],
      "columns_to_use": ["TRB", "STL"],
      "labels": ["Team"],
      "title": "Rebounds vs Steals",
      "point_image_template": "{Team}_logo.png",
    },
    "channels": [
      {"pubsub": "publish", "channel": "scatterTeam", "columnName": "Team"},
    ]
  },
  {
    "chartType": "table",
	  "chartId": "2",
    "app": {
      "width": 300,
      "height": 300,
    },  
    "data": {
      "title": "NBA Team Stats",
      "data_source": "nba",
      "aggregation_keys": [],
      "aggregations": [],
      "filters": [],
      "columns_to_use": [
          "Team",
          "FG%",
          "TRB",
      ],
      "labels": []
    },
    "options": {
      "fontSize": "0.7em",
      "backgroundColors": {
        "gradient": ["#ff0000", "#ffff00", "#00ff00"],
        "globalMinMax": false,
        "columnConfigs": [
          {
            "column": "Team",
            "disableGradient": true,
          },
        ]
      }
    },
    "channels": [
      {"pubsub": "subscribe", "channel": "scatterTeam", "columnName": "Team", "additional": "highlight", "operator": "equals"}
    ]
  },
  {
    "chartType": "details",
	"chartId": "3",
      "keyColumn": "Team",
      "app": {
        "width": 350,
        "height": 350,
      },
      "data": {
        "data_source": "nba",
        "aggregation_keys": [],
        "aggregations": [
          
        ],
        "filters": [],
        "columns_to_use": ["Team", "TRB", "STL"],
        "labels": ["Rebounds"]
      },
      "channels": [
        {"pubsub": "subscribe", "channel": "scatterTeam", "columnName": "Team", "operator": "equals"}
      ]
  },
  {
    "chartType": "bar",
	  "chartId": "4",
      "keyColumn": "Team",
      "app": {
        "width": 350,
        "height": 350,
      },
      "data": {
        "data_source": "nba",
        "aggregation_keys": [],
        "aggregations": [
          
        ],
        "filters": [],
        "columns_to_use": ["TRB"],
        "data_from_rows": {"key": "Team", "values": ["TRB"]},
        "labels": ["Rebounds"]
      },
      "channels": [
        {"pubsub": "subscribe", "channel": "scatterTeam", "columnName": "Team", "operator": "equals"}
      ]
      
  },
  {
    "chartType": "radar",
	  "chartId": "5",
      "keyColumn": "Team",
      "app": {
        "width": 350,
        "height": 350,
      },
      "data": {
        "data_source": "nba",
        "aggregation_keys": [],
        "aggregations": [
          
        ],
        "filters": [],
        "columns_to_use": ["TRB","AST","STL","BLK","FG%", "PTS"],
        "data_from_rows": {"key": "Team", "values": ["TRB"]},
        "labels": ["Rebounds"]
      },
      "channels": [
        {"pubsub": "subscribe", "channel": "scatterTeam", "columnName": "Team", "operator": "equals", "additional": "highlight"}
      ]
      
  },
  {
    "chartType": "table",
	  "chartId": "6",
    "app": {
      "width": 300,
      "height": 300,
    },  
    "data": {
      "title": "Quarterly Total Amount",
      "data_source": "business",
      "aggregation_keys": [],
      "aggregations": [],
      "date_aggregation": { "field": 'date', "mode": 'month' },
      "aggregate_keys": [
        { "field": 'date', "type": 'date', "mode": 'quarter', "rename": 'yearQuarter' }
      ],
      "aggregate_types": [
        { "field": 'amount', "type": 'sum', "rename": 'totalAmount' },
		    { "field": 'amount', "type": 'average', "rename": 'avgAmount' }
      ],
      "initial_sort": [{ "column": "totalAmount", "order": 'asc' }],
      "filters": [],
      "columns_to_use": [
          "yearQuarter",
          "totalAmount",
          "avgAmount",
      ],
      "labels": []
    },
    "options": {
      "fontSize": "0.7em",
      "backgroundColors": {
        "gradient": ["#ff0000", "#ffff00", "#00ff00"],
        "globalMinMax": false,
        "columnConfigs": [
          {
            "column": "id",
            "disableGradient": true,
          },
        ]
      }
    },
   
  },
  {
    "chartType": "bar",
	  "chartId": "7",
    "app": {
      "width": 300,
      "height": 300,
      "orientation": "vertical",
      "label_each_bar": false,
      "label_bar_end": true
    },  
    "data": {
      "title": "Yearly Total Amount by Category",
      "data_source": "business",
      "aggregation_keys": [],
      "aggregations": [],
      "date_aggregation": { "field": 'date', "mode": 'month' },
      "aggregate_keys": [
        { "field": 'date', "type": 'date', "mode": 'year', "rename": 'yearQuarter' }
      ],
      "aggregate_types": [
        { "field": 'amount', "type": 'sum', "rename": 'totalAmount' },
		    //{ "field": 'amount', "type": 'average', "rename": 'avgAmount' }
      ],
      "initial_sort": [{ "column": "yearQuarter", "order": 'asc' }],
      "filters": [],
      "columns_to_use": ["totalAmount"],
      "date_axis_column": "year",
      "data_from_rows": {"key": "year", "values": ["totalAmount"]},
      "labels": [],
      "secondary_key": "category",
      "aggregate_tiers": [
        {
          keyColumns: [{ field: "date", type: "date", mode: "year", rename: "year" }],
          aggregateColumns: [
            { field: "amount", type: "sum", rename: "totalAmount" }
          ]
        },
        {
          keyColumns: [{ field: "category", type: "text", rename: "category" }],
          aggregateColumns: [
            { field: "amount", type: "sum", rename: "category_total_amount" }
          ]
        }
      ]
    },
    "channels": [
      {"pubsub": "publish", "channel": "yearBarCategory",  "columnName": "category"},
      {"pubsub": "publish", "channel": "yearBarYear",  "columnName": "year"}
    ],
    "options": {
      
      }
    },
    {
      "chartType": "table",
	    "chartId": "8",
      "app": {
        "width": 300,
        "height": 300,
        "orientation": "vertical",
        "label_each_bar": false,
        "label_bar_end": true
      },  
      "data": {
        "title": "Yearly Total Amount by Category",
        "data_source": "business",
        "aggregation_keys": [],
        "aggregations": [],
        "aggregate_keys": [
          
        ],
        "aggregate_types": [
          
        ],
        "initial_sort": [{ "column": "yearQuarter", "order": 'asc' }],
        "filters": [],
        "columns_to_use": ["date", "amount", "category"],
        
        "labels": [],
        "aggregate_tiers": [
          
        ]
      },
      "channels": [
        {"pubsub": "subscribe", "channel": "yearBarCategory", "columnName": "category", "operator": "equals"},
		    {"pubsub": "subscribe", "channel": "yearBarYear", "columnName": "date", "operator": "year"},
      ],
      "options": {
        
        }
      },
      {
        "chartType": "dropdown",
        "chartId": "9",
        "app": {
          "width": 300,
          "height": 300,
        },
        "data": {
          "data_source": "nba",
          "aggregation_keys": [],
          "aggregations": [
          ],
          "filters": [],
          "columns_to_use": ["Total Revenue", "Gross Profit"],
          "labels": ["Team"],
          "columnName": "Team",
          "label": "Team"
        },
        "channels": [
          {"pubsub": "publish", "channel": "scatterTeam", "columnName": "Team"},
        ]
      },
      {
        "chartType": "speech",
        "chartId": "10",
        "app": {
          "width": 300,
          "height": 300,
        },
        "data": {
          "data_source": "nba",
          "aggregation_keys": [],
          "aggregations": [
          ],
          "filters": [],
          "columns_to_use": ["Total Revenue", "Gross Profit"],
          "labels": ["Team"],
          "columnName": "Team",
          "label": "Team"
        },
        "channels": [
          {"pubsub": "publish", "channel": "scatterTeam", "columnName": "Team"},
        ]
      },
];
/*
{
    "chartType": "bar",
      "keyColumn": "Team",
      "app": {
        "width": 350,
        "height": 350,
      },
      "data": {
        "data_source": "nba",
        "aggregation_keys": [],
        "aggregations": [
          
        ],
        "filters": [],
        "columns_to_use": ["TRB"],
        "labels": ["Rebounds"]
      },
      "channels": [
        {"pubsub": "subscribe", "channel": "scatterTeam", "field": "Team"}
      ]
      
  },
  */

const emptyConfigs = [];
const [chartConfigs, setChartConfigs] = useState(emptyConfigs1); 
//const [key, setKey] = useState(0);

/*
const saveConfiguration = () => {
  // Prompt the user for a name
  const name = window.prompt("Please enter a name for the configuration:");

  // Ensure the user entered a name
  if (name) {
    // Convert the config array to a JSON string
    const configJson = JSON.stringify(configs);

    // Save it to local storage using the name provided by the user
    localStorage.setItem(name, configJson);
    console.log("Configuration saved under the name:", name);
  } else {
    // Handle the case where the user pressed Cancel or entered an empty name
    console.log("Save operation cancelled or no name entered.");
  }
};
*/

/*
const loadConfiguration = (whichConfig = null) => {
  if( whichConfig == null ) {
    whichConfig = 'myAppConfig';
  }
  // Load the config JSON string from local storage
  const configJson = localStorage.getItem(whichConfig);
  // Parse it back to an array and set it to state
  if (configJson) {
    console.log("loading", JSON.parse(configJson));
    setChartConfigs(JSON.parse(configJson));
    resetChildComponent();
  }
};
*/

const loadConfiguration = (whichConfig = 'myAppConfig') => {
  // Construct the URL to fetch the configuration from
  const url = `${process.env.REACT_APP_CONFIGS_URL}/${whichConfig}.json`

  // Use the fetch API to get the configuration file from the server
  fetch(url)
    .then(response => {
      if (!response.ok) {
        // Handle HTTP errors
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json(); // Parse the response body as JSON
    })
    .then(config => {
      // Here, "config" is the parsed configuration object
      console.log("loading", config);
      setChartConfigs(config); // Set the loaded configuration to state
      resetChildComponent(); // Reset or reinitialize components as needed
    })
    .catch(error => {
      // Handle any errors that occurred during the fetch operation
      console.error("Error loading configuration:", error);
    });
};

const clearConfiguration = () => {

  setChartConfigs([]);
  resetChildComponent();
};
const [resetKey, setResetKey] = useState(0);
const resetChildComponent = () => {
  setResetKey(prevKey => prevKey + 1);
};

useEffect(() => {
  const path = window.location.pathname;
  const dashPattern = /^\/dash\/(.+)/; // Regex to match /dash/someConfig
  const match = path.match(dashPattern);
  console.log("using effect");
  if (match) {
    const someConfig = match[1]; // Extract the 'someConfig' part from the URL
    // Perform your loadItem operation with 'someConfig'
    console.log("Load item for config:", someConfig);
    loadConfiguration(someConfig);
  }
}, []); // Empty dependency array means this runs once on mount



  //const barchart_data = transformData(sampleData, "Tm", ["RushYds", "PassYds", "TotYds"]);

/*
  return (
    <div>
      <FileUpload />
    </div>
  );
}

<FileUpload />
*/
  console.log(chartConfigs);
  return (
    <div>
      <PubSubProvider  key={resetKey} configs={chartConfigs}>
      </PubSubProvider>
      {/*
      <div className="button-container">
        <button className="button" onClick={loadConfiguration}>App Load</button>
        <button className="button" onClick={clearConfiguration}>App Clear</button>
        </div>
      */}
    </div>
    
  );
  }

export default App;