import React, { useState, useEffect } from 'react';

function detectedVoice(){
    var ses = new window.webkitSpeechRecognition();
    ses.interimResults = true;
    ses.maxAlternatives = 1;
    ses.continuous = true;
    ses.interimResults = true;
    ses.onstart = true;
    ses.onend = function() {
      ses.start();
    };
    ses.onresult = function(e){
      if (e.results.length > 0) {
        let sonuc = e.results[e.results.length -1];
        if (sonuc.isFinal) {
          var result = sonuc[0].transcript;
          console.log(result);
        }
      }
    }
  }


const SpeechBox = ({ rawData, channels = [], data={}, app={} }) => {
	// State to store the recognized text
	const [text, setText] = useState('');
	const [listening, setListening] = useState(false);

	// Speech recognition setup
	const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
	const recognition = new SpeechRecognition();

	recognition.onstart = () => {
		console.log('Voice recognition activated. Try speaking into the microphone.');
		setListening(true);
	};

	recognition.onresult = (event) => {
		const current = event.resultIndex;
		const transcript = event.results[current][0].transcript;
		setText(transcript);
		setListening(false);
	};

	const startListening = () => {
        detectedVoice();
	};

    const stopListening = () => {
		recognition.abort();
	};

	useEffect(() => {
		recognition.onend = () => {
			if (listening) recognition.start();  // Keeps the recognition active if you're still listening
			else recognition.stop();
		};
	}, [listening]);

	return (
		<div>
			<h2>Speech to Text Converter</h2>
			<button onClick={startListening}>{listening ? 'Listening...' : 'Start Listening'}</button>
            <button onClick={stopListening}>{'Stop Listening'}</button>
			<textarea value={text} onChange={(e) => setText(e.target.value)} placeholder="Speak something..." />
		</div>
	);
};

export default SpeechBox;