import React, { useState, useEffect, useMemo, useRef } from 'react';
import { usePubSub } from './PubSubContext';
import { dataFromColumns, dataFromRows } from './TableOperations';
import { all } from 'axios';
import { BottomAxis, LeftAxis, TopAxis, calculateNiceTickSize, calculateDateTickSize } from './Axis';


const initialColors = ['#009879', '#FF5733', '#C70039', '#900C3F', '#581845'];


const BarChart = ({ rawData,initialDataKey = 'Miami Dolphins', channels = [], data={}, app={}}) => {
    console.log("rawdata in barchart: ", rawData);
    const { subscribe, publish } = usePubSub();
    const [tooltip, setTooltip] = useState(null);
    const [allTimeMax, setAllTimeMax] = useState(-Infinity);
    const { width, height, orientation = 'horizontal' } = app;
    const labelEachBar = app?.label_each_bar !== undefined ? app.label_each_bar : true;
    const [categoryColors, setCategoryColors] = useState({});
    const [categoryOrder, setCategoryOrder] = useState([]);
    const svgRef = useRef(null);


    const parsedData = useMemo(() => rawData, [rawData]);
    console.log("barchart raw: ", rawData); 

    var columns_to_include = data["columns_to_use"];

    
    const transformData = (data, columnsToInclude) => {
        if (!data || !Array.isArray(data.headers) || !Array.isArray(data.rows) || data.rows.length === 0) {
            return {}; // Return an empty object if data is empty or not in the expected format
        }
    
        const result = {};
        const columnIndices = columnsToInclude.map(col => data.headers.indexOf(col));
    
        const isFloat = (str) => {
            return /^-?\d+\.\d+$/.test(str) || /^-?\d+$/.test(str);
        }
    
        // Focus on the first row only
        const firstRow = data.rows[0];
        columnIndices.forEach((colIndex, i) => {
            const cellValue = firstRow[colIndex];
            if (cellValue !== undefined) {
                result[columnsToInclude[i]] = isFloat(cellValue) ? parseFloat(cellValue) : cellValue;
            }
        });
    
        //return result, data.secondaryValues || {};
        return result;
    };
    

    const isFloat = (n) => {
        return Number(n) === n && n % 1 !== 0;
    };
    
    const filterFloatsFromDict = (data) => {
        const result = {};
        Object.keys(data).forEach(key => {
            const value = data[key][0]; // Get the first element of the array
            // Check if the value is a number (this will be true for both integers and floats)
            if (!isNaN(parseFloat(value)) && isFinite(value)) {
                result[key] = value; // Add to result if it's a number    
            } 
        });
        return result;
    };

    const calculateMaxValue = (inputMax) => {
        // Directly return inputMax if it's set (not undefined and not null)
        if ("inputMax" in app && app.inputMax !== null && app.inputMax !== "null") {
            return app.inputMax;
        }
        
        // Return 0 if allTimeMax is -Infinity, otherwise return allTimeMax
        return allTimeMax === -Infinity ? 0 : allTimeMax;
    };

    const determinePadding = (app, width, height, hasAxisLabel) => {
        const screenWidth = window.innerWidth; // Get the current width of the browser window
        const isMobile = screenWidth < 768; // Example condition for mobile
    
        return {
            leftPad: app?.leftPad !== undefined ? app.leftPad : (isMobile ? 10 : (hasAxisLabel ? 50 : 10)),
            rightPad: app?.rightPad !== undefined ? app.rightPad : (isMobile ? 10 : 20),
            topPad: app?.topPad !== undefined ? app.topPad : 40,
            bottomPad: app?.bottomPad !== undefined ? app.bottomPad : (isMobile ? 20 : 40),
        };
    };
    
   let transformedData = {};
   if (rawData == null || !rawData.headers || rawData.headers.length === 0) {


   }
   else if ("data_from_columns" in data) {
        transformedData = dataFromColumns(rawData);
        console.log("transformed data from columns: ", transformedData);
   }
    else if ("data_from_rows" in data) {
        transformedData = dataFromRows(rawData, data.data_from_rows.key, data.data_from_rows.values);
        console.log("transformed data from rows: ", transformedData);
    }
   
    console.log("rawData:", rawData);
    //const transformedData = transformData(parsedData, columns_to_include);
    let stackedData = {}; // Declare stackedData outside the if-else structure
    let primaryKey = "";
    if( "date_axis_column" in data){
        primaryKey = data.date_axis_column;
    }
    if (rawData.more) {
        rawData.rows.forEach(([quarter, totalAmount], index) => {
            if (!stackedData[quarter]) {
                stackedData[quarter] = [];
            }
            let secondaryKey = data.secondary_key;
            
            
            // Aggregate the corresponding "more" data for the current index
            const categoryData = rawData.more[index];
            
            categoryData.rows.forEach(([category, categoryTotalAmount]) => {
                const tempdatakeys = {};
                tempdatakeys[primaryKey] = quarter;
                tempdatakeys[secondaryKey] = category;
                stackedData[quarter].push({
                    key: category,
                    value: categoryTotalAmount,
                    datakeys: tempdatakeys
                });
            });
        });
    } else {
        stackedData = {}; // Assign an empty object otherwise
    }

    transformedData = filterFloatsFromDict(transformedData);

    var allData = transformedData;
    var bar_data = transformedData;
    
    useEffect(() => {
        // Extract values and find the current max
        const currentValues = Object.values(bar_data);
        const currentMax = Math.max(...currentValues);

        // Update the all-time max if the current max is greater
        if (currentMax > allTimeMax) {
            setAllTimeMax(currentMax);
        }

        // Add more logic here if necessary
    }, [bar_data]); // Dependency array includes 'data', so this runs when 'data' changes

    const { leftPad, rightPad, topPad, bottomPad } = determinePadding(app, width, height, true);

    const maxValue = calculateMaxValue(allTimeMax);
    const { ticks, endTick } = calculateNiceTickSize(0, maxValue, 5);
    console.log("maxValue: ", maxValue);    
    console.log("ticks: ", ticks);
    const adjustedHeight = height - topPad - bottomPad;
    const adjustedWidth = width - leftPad - rightPad;

    let date_ticks;

    if ('date_axis_column' in data) {
        const dates = Object.keys(bar_data);
        if (dates.length > 0 && dates[0] !== undefined && dates[0] !== "undefined") {
            console.log("dates: ", dates);
            date_ticks = calculateDateTickSize(dates[0], dates[dates.length - 1]);
            console.log("date_ticks: ", date_ticks);
            date_ticks = dates;
        }
    }
    
    
    
    const barHeight = adjustedHeight / Math.max(1, Object.keys(bar_data).length); 

    const barWidth = adjustedWidth / Math.max(1, Object.keys(bar_data).length);

    function calculateOptimalFontSize(svgWidth, maxLength, padding) {
        const availableWidth = svgWidth - padding;
        const fontSize = availableWidth / (maxLength * 0.5); // Assuming average character width is half the font size
        return fontSize;
    }

    const fontSize = calculateOptimalFontSize(width, 30, 10);
    const labelBarEnd = app?.label_bar_end !== undefined ? app.label_bar_end : false;

    const generateAdditionalColors = (numColors) => {
        let colors = [];
        for (let i = 0; i < numColors; i++) {
            // Generate a color
            colors.push(`hsl(${360 * (i / numColors)}, 100%, 50%)`);
        }
        return colors;
    };

    useEffect(() => {
        let categories = new Set();
        Object.values(stackedData).forEach(stack => {
            stack.forEach(item => categories.add(item.key));
        });

        // Create a sorted array of categories
        const sortedCategories = Array.from(categories).sort();
        setCategoryOrder(sortedCategories);

        const newCategoryColors = {};
        const additionalColors = generateAdditionalColors(sortedCategories.length - initialColors.length);

        sortedCategories.forEach((category, index) => {
            newCategoryColors[category] = index < initialColors.length ? initialColors[index] : additionalColors[index - initialColors.length];
        });

        setCategoryColors(newCategoryColors);
    }, [rawData]);


    const handleMouseOver = (item, event, barKey) => {
        //event.preventDefault(); 
        const svgElement = event.target.closest('svg');
        if (!svgElement) {
            console.log('SVG element not found');
            return;
        }
        console.log("barKey: ", barKey);
        const totalAmount = stackedData[barKey].reduce((acc, cur) => acc + cur.value, 0);
        
        const rect = svgElement.getBoundingClientRect();
        const offsetX = 10; // Offset x coordinate for the tooltip
        const offsetY = -40; // Offset y coordinate for the tooltip
    
        // Determine the event type and get the correct coordinates
        let clientX, clientY;
        if (event.type === 'touchstart' || event.type === 'touchmove') {
            const touch = event.touches[0];
            clientX = touch.clientX;
            clientY = touch.clientY;
        } else {
            clientX = event.clientX;
            clientY = event.clientY;
        }
    
        setTooltip({
            data: item,
            // Calculate x and y positions relative to the SVG's bounding rectangle
            total: totalAmount,
            date: barKey, // Assuming barKey is the date or identifier
            x: clientX - rect.left + offsetX,
            y: clientY - rect.top + offsetY
        });
        console.log("PUBLISH tooltip data: ", item);
    
        channels.forEach(channelConfig => {
            //const values = extractValuesFromData(item, channelConfig.fields);
            if (channelConfig.pubsub === 'publish') {
                console.log("filters for PUBLISHING: ", item.datakeys[channelConfig.columnName]);
                publish(channelConfig.channel, [item.datakeys[channelConfig.columnName]]);
            }
        });
    };

    
    const handleTouchMove = (event) => {
        event.preventDefault();
		let clientX, clientY;
        if (event.type === 'touchstart' || event.type === 'touchmove') {
            const touch = event.touches[0];
            clientX = touch.clientX;
            clientY = touch.clientY;
        } else {
            clientX = event.clientX;
            clientY = event.clientY;
        }
		const target = document.elementFromPoint(clientX, clientY);
		if (target && target.tagName === 'rect') {
            const item = JSON.parse(target.getAttribute('data-item'));
            const barKey = target.getAttribute('data-bar-key');
            if( barKey !== undefined && barKey !== null){
			    handleMouseOver(item, event, barKey);
            }
		}
	};

	const handleMouseOut = () => {
		//setTooltip(null);
	};

    const preventDefault = (event) => {
		event.preventDefault();
	};
    //style={{ touchAction: 'none' }}

    return (
        <div
            style={{ touchAction: 'none' }}
            onTouchMove={handleTouchMove}
            onMouseMove={handleTouchMove}
            onTouchStart={preventDefault}
        >
            <svg 
                width={width} 
                height={height}
                //style={{ touchAction: 'none' }}
                ref={svgRef}
            >
                
            {Object.keys(stackedData).length > 0 ? (
                    Object.keys(stackedData).map((key, index) => {
                        let cumulativeValue = 0;

                        return (
                            <g key={`stack-${key}`}>
                                {categoryOrder.map((category) => {
                                    const item = stackedData[key].find(item => item.key === category);
                                    if (!item) return null; // If the category doesn't exist in this stack, don't render anything

                                    const scaledValue = (item.value / endTick) * (orientation === 'horizontal' ? adjustedWidth : adjustedHeight);
                                    const xPosition = orientation === 'horizontal' ? cumulativeValue + leftPad : leftPad + barWidth * index;
                                    const yPosition = orientation === 'horizontal' ? barHeight * index + topPad : height - bottomPad - cumulativeValue - scaledValue;
                                    cumulativeValue += scaledValue;

                                    const color = categoryColors[category] || '#000000';

                                    return (
                                        <rect
                                            key={`${key}-${category}`}
                                            x={xPosition}
                                            y={yPosition}
                                            width={orientation === 'horizontal' ? scaledValue : barWidth - 5}
                                            height={orientation === 'horizontal' ? barHeight - 5 : scaledValue}
                                            fill={color}
                                            data-item={JSON.stringify(item)}
                                            data-bar-key={key}
                                        />
                                    );
                                })}
                            </g>
                        );
                    })
                ) : (
                // Existing logic for non-stacked data
                Object.keys(bar_data).map((key, index) => {
                    const value = bar_data[key];
                    const scaledValue = (value / endTick) * (orientation === 'horizontal' ? adjustedWidth : adjustedHeight);

                    return orientation === 'horizontal' ? (
                        <g key={key}>
                            <rect x={leftPad} y={topPad + barHeight * index} width={scaledValue} height={barHeight - 5} fill="#009879" />
                            {labelEachBar && (
                            <text x={5 + leftPad} y={topPad + (barHeight * index) + barHeight / 2} alignmentBaseline="middle" fill="#000" fontSize={fontSize}>
                                {key}: {value}
                            </text>
                            )}
                            {labelBarEnd && (
                                <text x={leftPad + scaledValue + 5} y={topPad + (barHeight * index) + barHeight / 2} alignmentBaseline="middle" fill="#000" fontSize={fontSize * 0.75}>
                                    {value}
                                </text>
                            )}
                        </g>
                    ) : (
                        <g key={key}>
                            <rect x={leftPad + barWidth * index} y={height -bottomPad - scaledValue} width={barWidth - 5} height={scaledValue} fill="#009879" />
                            {labelEachBar && (
                            <text x={leftPad + (barWidth * index) + barWidth / 2} y={height - bottomPad - 5} alignmentBaseline="middle" fill="#000" fontSize={fontSize} transform={`rotate(-90, ${leftPad + (barWidth * index) + barWidth / 2}, ${height - bottomPad- 5})`}>
                                {key}: {value}
                            </text> 
                            )}
                            {labelBarEnd && (
                                <text x={leftPad + (barWidth * index) + barWidth/2 } y={adjustedHeight - scaledValue - 5} text-anchor="middle" fill="#000" fontSize={fontSize * 0.4}>
                                    {value}
                                </text>
                            )}
                        </g>
                    );
                }))}
                
                {orientation === 'horizontal' ? (
                        <BottomAxis
                        orient="bottom" // Assuming 'bottom' is correct for horizontal orientation
                        ticks={ticks}
                        tickSize={10}
                        width={width}
                        height={height}
                        leftPadding={leftPad}
                        rightPadding={rightPad}
                        bottomPadding={bottomPad}
                        tickFormat={value => value.toString()}
                        axisLabel="X Axis Label"
                        />
                    ) : (
                        <LeftAxis
                        orient="left"
                        ticks={ticks}
                        tickSize={10}
                        width={width}
                        height={height}
                        leftPadding={leftPad}
                        rightPadding={rightPad}
                        bottomPadding={bottomPad}
                        tickFormat={value => value.toString()}
                        axisLabel="Y Axis Label" // Change the label as appropriate for vertical orientation
                        />
                )}

                {/* Conditionally render another BottomAxis if date_ticks is set */}
                {date_ticks && date_ticks.length > 0 && (
                    <BottomAxis
                        orient="bottom"
                        ticks={date_ticks}
                        tickSize={10}
                        width={width}
                        height={height}
                        leftPadding={leftPad+barWidth/2-5/2}
                        rightPadding={rightPad+barWidth/2}
                        bottomPadding={bottomPad}
                        tickFormat={(value) => value.toString()}
                        axisLabel="Date Axis Label"
                        valueInThousands={false}
                        enableConvertToMagnitude={false}
                    />
                )}
                {data.title && data.title.trim() !== "" && (
                    <TopAxis
                        width={width}
                        tickFormat={value => value.toString()}
                        leftPadding={leftPad}
                        topPadding={topPad} // You might need to adjust or add this prop based on your TopAxis component props
                        axisLabel={data.title}
                    />
                )}
            </svg>
            {tooltip && (
                <div style={{
                    position: 'absolute',
                    left: `${tooltip.x}px`,
                    top: `${tooltip.y}px`,
                    backgroundColor: '#333',
                    color: '#fff',
                    padding: '5px',
                    borderRadius: '4px',
                    fontSize: '12px',
                    pointerEvents: 'none',
                    zIndex: 1000
                }}>
                    <span>{`${tooltip.data.key}: ${tooltip.data.value}`}</span><br />
                    <span>{`Total: ${tooltip.total}`}</span><br />
                    <span>{`Date: ${tooltip.date}`}</span>
                </div>
            )}
        </div>
    );
};

export default BarChart;