import React, { useState, useEffect, useMemo } from 'react';
import { usePubSub } from './PubSubContext';
import { dataFromColumns, dataFromRows } from './TableOperations';
import { BottomAxis, LeftAxis, calculateNiceTickSize } from './Axis';

const LineChart = ({ rawData, initialDataKey = 'Miami Dolphins', channels = [], data = {}, app = {} }) => {
    const { width, height } = app;
    const [allTimeMax, setAllTimeMax] = useState(-Infinity);
    const [tooltip, setTooltip] = useState({ visible: false, content: '', x: 0, y: 0 });


    const filterFloatsFromDict = (data) => {
      const result = {};
      Object.keys(data).forEach(key => {
          const value = data[key][0]; // Get the first element of the array
          // Check if the value is a number (this will be true for both integers and floats)
          if (!isNaN(parseFloat(value)) && isFinite(value)) {
              result[key] = value; // Add to result if it's a number    
          } 
      });
      return result;
    };

    let transformedData = {};
    if (rawData == null || !rawData.headers || rawData.headers.length === 0) {


    }
    else if ("data_from_columns" in data) {
          transformedData = dataFromColumns(rawData);
          console.log("transformed data from columns: ", transformedData);
    }
    else if ("data_from_rows" in data) {
        transformedData = dataFromRows(rawData, data.data_from_rows.key, data.data_from_rows.values);
        console.log("transformed data from rows: ", transformedData);
    }
    transformedData = filterFloatsFromDict(transformedData);
    //transformedData = Object.values(transformedData);
    console.log("filtered transformed data: ", transformedData);


    useEffect(() => {
        // Extract values and find the current max
        console.log("transformed data: ", transformedData);
        //const currentValues = Object.values(transformedData).map(d => d.value);
        const currentValues = Object.values(transformedData);
        //console.log("current values: ", currentValues);
        const currentMax = Math.max(...currentValues, 0);
        //console.log("current max: ", currentMax);

        // Update the all-time max if the current max is greater
        if (currentMax > allTimeMax) {
            setAllTimeMax(currentMax);
        }
    }, [transformedData, allTimeMax]);

    const leftPad = 80;
    const rightPad = 40;
    const topPad = 20;
    const bottomPad = 40;
    const maxValue = allTimeMax === -Infinity ? 0 : allTimeMax;
    const { ticks, endTick } = calculateNiceTickSize(0, maxValue, 5);
    const adjustedHeight = height - topPad - bottomPad;
    const adjustedWidth = width - leftPad - rightPad;
    console.log("linegraph data", transformedData);

    // Assuming data is an array of objects with 'key' and 'value'
    const points = Object.keys(transformedData).map((key, index) => {
        const value = transformedData[key];
        const x = leftPad + (adjustedWidth / (Object.keys(transformedData).length - 1)) * index;
        const y = adjustedHeight - ((value / maxValue) * adjustedHeight) + topPad;
        console.log("linegraph points", x, y);
        return { x, y, key, value };
    });

    const pathD = points.map((point, index) => `${index === 0 ? 'M' : 'L'}${point.x},${point.y}`).join(' ');

    const handleMouseEnter = (event, point) => {
      // Get the bounding rectangle of the SVG element
      const svgRect = event.currentTarget.ownerSVGElement.getBoundingClientRect();
      
      // Calculate the tooltip's position relative to the SVG by subtracting the SVG's top and left
      // positions from the event's clientX and clientY positions
      const x = event.clientX - svgRect.left;
      const y = event.clientY - svgRect.top;
  
      setTooltip({
          visible: true,
          content: `${point.key}: ${point.value}`,
          // Position the tooltip with respect to the SVG's current position on the page
          x: x,
          y: y
      });
    };

    const handleMouseLeave = () => {
        setTooltip({ ...tooltip, visible: false });
    };

    return (
        <div>
            <svg width={width} height={height}>
                <path d={pathD} fill="none" stroke="#0074d9" strokeWidth="2" />
                {points.map((point, index) => (
                  <circle
                    key={index}
                    cx={point.x}
                    cy={point.y}
                    r="5"
                    fill="#0074d9"
                    onMouseEnter={(e) => handleMouseEnter(e, point)}
                    onMouseLeave={handleMouseLeave}
                  />
                ))}
                <BottomAxis
                    orient="bottom"
                    ticks={ticks}
                    tickSize={10}
                    width={width}
                    height={height}
                    leftPadding={leftPad}
                    rightPadding={rightPad}
                    bottomPadding={bottomPad}
                    tickFormat={value => value.toString()}
                    axisLabel="X Axis Label"
                />
                <LeftAxis
                    orient="left"
                    ticks={ticks}
                    tickSize={10}
                    width={width}
                    height={height}
                    leftPadding={leftPad}
                    rightPadding={rightPad}
                    bottomPadding={bottomPad}
                    tickFormat={value => value.toString()}
                    axisLabel="Y Axis Label"
                />
            </svg>
            {tooltip.visible && (
                <div
                    style={{
                        position: 'absolute',
                        left: tooltip.x,
                        top: tooltip.y,
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        padding: '5px',
                        borderRadius: '5px',
                        pointerEvents: 'none', // Make the tooltip ignore mouse events
                    }}
                >
                    {tooltip.content}
                </div>
            )}
        </div>
    );
};

export default LineChart;