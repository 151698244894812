import React, { useEffect, useRef, useState } from 'react';
import { convertToMagnitude } from './TableOperations';
import { parse, addMonths, addQuarters, format, differenceInMonths, isAfter, startOfMonth, startOfQuarter } from 'date-fns';


interface BottomAxisProps {
  ticks: number[];
  tickSize: number;
  width: number;
  tickFormat?: (value: any) => string;
  labelRotation?: number;
  axisLabel?: string;
  leftPadding?: number;
  rightPadding?: number;
  bottomPadding?: number;
}

const BottomAxis: React.FC<BottomAxisProps> = ({
  ticks,
  tickSize,
  width,
  height,
  tickFormat = (value) => value.toString(),
  labelRotation = 0,
  axisLabel,
  leftPadding = 10,
  rightPadding = 10,
  bottomPadding = 50,
  valueInThousands = true,
  enableConvertToMagnitude = true
}) => {
  const axisWidth = width - leftPadding - rightPadding;
  const calculateTickPosition = (index: number, total: number) => axisWidth / (total - 1) * index + leftPadding;
  const labelHeight = 10;
  //const bottomTickPosition = height - tickSize - bottomPadding - labelHeight;
  const bottomTickPosition = height - bottomPadding;
  const betweenTickLabelHeight = 5;

  const renderTicks = () => ticks.map((tick, index) => {
    const position = calculateTickPosition(index, ticks.length);
		const tickLabel = enableConvertToMagnitude
			? convertToMagnitude(tick, valueInThousands)
			: tickFormat(tick);
		return (
			<g key={index} transform={`translate(${position},0)`}>
				<line x1="0" y1={bottomTickPosition} x2="0" y2={bottomTickPosition + tickSize} stroke="black" />
				<text
					x="0"
					y={bottomTickPosition + tickSize + betweenTickLabelHeight}
					dy=".71em"
					textAnchor="middle"
					transform={`rotate(${labelRotation},0,${tickSize + 5})`}
				>
					{tickLabel}
				</text>
			</g>
		);
  });

  const renderAxisLabel = () => axisLabel && (
    <text x={leftPadding + (width-leftPadding) / 2} y={bottomTickPosition + tickSize + 2*betweenTickLabelHeight + 2*labelHeight} textAnchor="middle">{axisLabel}</text>
  );

  return (
    <g className="axis-bottom">
      {renderTicks()}
      {renderAxisLabel()}
    </g>
  );
};



/*
interface LeftAxisProps {
  ticks: number[];
  tickSize: number;
  height: number;
  tickFormat?: (value: any) => string;
  axisLabel?: string;
  topPadding?: number;
  bottomPadding?: number;
  leftPadding?: number;
}

const LeftAxis: React.FC<LeftAxisProps> = ({
  ticks,
  tickSize,
  height,
  tickFormat = (value) => value.toString(),
  axisLabel,
  topPadding = 20,
  bottomPadding = 40,
  leftPadding = 100,
  tickLabelWidth = 30,
  valueInThousands = true,
}) => {
  const axisHeight = height - topPadding - bottomPadding;
  const calculateTickPosition = (index: number, total: number) => 
    height - bottomPadding - (axisHeight / (total - 1) * index);

    

  const renderTicks = () => ticks.map((tick, index) => {
    const position = calculateTickPosition(index, ticks.length);
    return (
      <g key={index}>

        <line x1={leftPadding} y1={position} x2={leftPadding - tickSize} y2={position} stroke="black" />

        <text
          x={leftPadding - 5 - tickSize}
          y={position}
          dy=".32em"
          textAnchor="end"
        >
          {convertToMagnitude(tick, valueInThousands)}
        </text>
      </g>
    );
  });

  const renderAxisLabel = () => axisLabel && (
    <text
      
      transform={`translate(${tickLabelWidth / 2}, ${topPadding + axisHeight / 2}) rotate(-90)`}
      textAnchor="middle"
    >
      {axisLabel}
    </text>
  );

  return (
    <g className="axis-left" transform={`translate(0, 0)`}>
      {renderTicks()}
      {renderAxisLabel()}
    </g>
  );
};
*/
interface LeftAxisProps {
	ticks: number[];
	tickSize: number;
	height: number;
	tickFormat?: (value: any) => string;
	axisLabel?: string;
	topPadding?: number;
	bottomPadding?: number;
	leftPadding?: number;
}

const LeftAxis = ({
	ticks,
	tickSize,
	height,
	tickFormat = (value) => value.toString(),
	axisLabel,
	topPadding = 20,
	bottomPadding = 40,
	leftPadding = 100,
	tickLabelWidth = 30,
	valueInThousands = true,
}) => {
	const axisHeight = height - topPadding - bottomPadding;
	const textRefs = useRef([]);
	const axisLabelRef = useRef(null);
	const [overlapAmount, setOverlapAmount] = useState(0);

	const calculateTickPosition = (index, total) => 
		height - bottomPadding - (axisHeight / (total - 1) * index);

	const convertToMagnitude = (value, inThousands) => 
		inThousands ? `${(value / 1000).toFixed(1)}k` : value.toString();

	const calculateOverlapAmount = () => {
		let totalOverlap = 0;

		const allRefs = [...textRefs.current, axisLabelRef.current];

		for (let i = 0; i < allRefs.length; i++) {
			const current = allRefs[i];
			if (current) {
				const currentBBox = current.getBBox();
				for (let j = i + 1; j < allRefs.length; j++) {
					const next = allRefs[j];
					if (next) {
						const nextBBox = next.getBBox();
						
						// Calculate overlap dimensions
						const verticalOverlap = Math.max(0, Math.min(currentBBox.y + currentBBox.height, nextBBox.y + nextBBox.height) - Math.max(currentBBox.y, nextBBox.y));
						const horizontalOverlap = Math.max(0, Math.min(currentBBox.x + currentBBox.width, nextBBox.x + nextBBox.width) - Math.max(currentBBox.x, nextBBox.x));

						if (verticalOverlap > 0 && horizontalOverlap > 0) {
							const overlapArea = verticalOverlap * horizontalOverlap;
							totalOverlap += overlapArea;
							console.log("Overlap between labels", current.textContent, next.textContent, verticalOverlap, horizontalOverlap, overlapArea);
						}
					}
				}
			}
		}

		setOverlapAmount(totalOverlap);
	};

	useEffect(() => {
		calculateOverlapAmount();
	}, [ticks, topPadding, bottomPadding, leftPadding, axisLabel]);

	const renderTicks = () => ticks.map((tick, index) => {
		const position = calculateTickPosition(index, ticks.length);
		return (
			<g key={index}>
				{/* Draw horizontal lines for ticks */}
				<line x1={leftPadding} y1={position} x2={leftPadding - tickSize} y2={position} stroke="black" />
				{/* Tick labels with normal orientation */}
				<text
					ref={(el) => textRefs.current[index] = el}
					x={leftPadding - 5 - tickSize}
					y={position}
					dy=".32em"
					textAnchor="end"
				>
					{convertToMagnitude(tick, valueInThousands)}
				</text>
			</g>
		);
	});

	const renderAxisLabel = () => axisLabel && (
		<text
			ref={axisLabelRef}
			transform={`translate(${tickLabelWidth / 2}, ${topPadding + axisHeight / 2}) rotate(-90)`}
			textAnchor="middle"
		>
			{axisLabel}
		</text>
	);

	return (
		<g className="axis-left" transform={`translate(0, 0)`}>
			{renderTicks()}
			{renderAxisLabel()}
			{overlapAmount > 0 && (
				<text x={leftPadding} y={topPadding - 10} fill="red" textAnchor="start">
					Overlap amount: {overlapAmount}
				</text>
			)}
		</g>
	);
};

function calculateNiceTickSize(start, end, desiredTicks = 5) {
  const initialRange = end - start;
  const initialTickSize = initialRange / (desiredTicks - 1);

  const exponent = Math.floor(Math.log10(initialTickSize));
  const fraction = initialTickSize / Math.pow(10, exponent);
  const niceSteps = [1, 1.5, 2, 2.5, 5, 10];

  let niceTickSize = niceSteps.find(step => fraction <= step) * Math.pow(10, exponent);
  let niceEndPoint = start + niceTickSize * (desiredTicks - 1);

  if (niceEndPoint < end) {
    const additionalTicks = Math.ceil((end - niceEndPoint) / niceTickSize);
    niceEndPoint += niceTickSize * additionalTicks;
  }

  // Generate numerical tick values
  let numericTicks = [];
  for (let i = 0; i < desiredTicks; i++) {
    const tickValue = start + i * niceTickSize;
    if (tickValue <= niceEndPoint) {
      numericTicks.push(tickValue);
    }
  }

  // Determine if decimals are needed
  const requiresDecimals = numericTicks.some(tick => !Number.isInteger(tick));

  // Format tick values as strings, with or without decimals
  const ticks = numericTicks.map(tick => requiresDecimals ? tick.toFixed(2) : tick.toString());

  // Ensure the last tick is exactly the nice end point, formatted correctly
  if (ticks.length > 0 && numericTicks[numericTicks.length - 1] !== niceEndPoint) {
    ticks[ticks.length - 1] = requiresDecimals ? niceEndPoint.toFixed(2) : niceEndPoint.toString();
  }

  return { ticks, endTick: niceEndPoint };
}

function parseDate(dateStr) {
	const dateFormats = [
		'yyyy-MM-dd',
		'yyyy-MM',
		'yyyy-MM-dd HH:mm:ss',
		'yyyy-Qq', // 'Q' for quarter
    'yyyy'
		// Add more formats as needed
	];
	for (let formatStr of dateFormats) {
		try {
			let parsedDate = parse(dateStr, formatStr, new Date());
			if (!isNaN(parsedDate)) return parsedDate;
		} catch (e) {
			// Ignore parsing errors
		}
	}
	throw new Error(`Unrecognized date format: ${dateStr}`);
}

function calculateDateTickSize(start, end, desiredTicks = 5, alignWithDataPoints = false, dataPoints = []) {
	const startDate = parseDate(start);
	const endDate = parseDate(end);
	const rangeInMonths = differenceInMonths(endDate, startDate);
	const initialTickSizeInMonths = Math.ceil(rangeInMonths / (desiredTicks - 1));

	let addFn;
	let tickIntervalInMonths;
	let formatStr;

	if (rangeInMonths > 36) { // More than 3 years
		addFn = addQuarters;
		tickIntervalInMonths = 3 * Math.ceil(initialTickSizeInMonths / 3);
		formatStr = 'yyyy-Qq';
	} else {
		addFn = addMonths;
		tickIntervalInMonths = Math.ceil(initialTickSizeInMonths);
		formatStr = 'yyyy-MM';
	}

	let niceTickSize = tickIntervalInMonths;
	let niceEndPoint = addFn(startDate, tickIntervalInMonths * (desiredTicks - 1));

	if (isAfter(niceEndPoint, endDate)) {
		const additionalTicks = Math.ceil(differenceInMonths(endDate, niceEndPoint) / tickIntervalInMonths);
		niceEndPoint = addFn(niceEndPoint, tickIntervalInMonths * additionalTicks);
	}

	let ticks = [];
	let currentTickDate = startDate;
	for (let i = 0; i < desiredTicks; i++) {
		if (isAfter(currentTickDate, niceEndPoint)) break;
		ticks.push(format(currentTickDate, formatStr));
		currentTickDate = addFn(currentTickDate, tickIntervalInMonths);
	}

	if (alignWithDataPoints) {
		ticks = ticks.filter(tick => dataPoints.includes(tick));
	}

	// Ensure the last tick is exactly the nice end point, formatted correctly
	if (ticks.length > 0 && format(parseDate(ticks[ticks.length - 1]), formatStr) !== format(niceEndPoint, formatStr)) {
		ticks[ticks.length - 1] = format(niceEndPoint, formatStr);
	}

	return { ticks, endTick: format(niceEndPoint, formatStr) };
}


interface TopAxisProps {
  ticks: number[];
  tickSize: number;
  width: number;
  tickFormat?: (value: any) => string;
  labelRotation?: number;
  axisLabel?: string;
  leftPadding?: number;
  rightPadding?: number;
  topPadding?: number;
}

const TopAxis: React.FC<TopAxisProps> = ({
  ticks = [],
  tickSize,
  width,
  tickFormat = (value) => value.toString(),
  labelRotation = 0,
  axisLabel,
  leftPadding = 10,
  rightPadding = 10,
  topPadding = 50, // Adjust top padding as needed
}) => {
  const axisWidth = width - leftPadding - rightPadding;
  const calculateTickPosition = (index: number, total: number) => axisWidth / (total - 1) * index + leftPadding;

  // Adjusted positions for top alignment
  const topTickPosition = topPadding;
  const betweenTickLabelHeight = 5;

  const renderTicks = () => ticks.map((tick, index) => {
    const position = calculateTickPosition(index, ticks.length);
    return (
      <g key={index} transform={`translate(${position},${topTickPosition})`}>
        <line x1="0" y1="0" x2="0" y2={-tickSize} stroke="black" />
        <text
          x="0"
          y={-(tickSize + betweenTickLabelHeight)}
          dy="-0.32em" // Adjust this to properly align the text vertically
          textAnchor="middle"
          transform={`rotate(${labelRotation},0,${-(tickSize + 5)})`}
        >
          {tickFormat(tick)}
        </text>
      </g>
    );
  });

  const renderAxisLabel = () => axisLabel && (
    <text x={width/ 2} y={topPadding/2} textAnchor="middle">{axisLabel}</text> // Adjusted y position for axis label
  );


  return (
    <g className="axis-top">
      {renderTicks()}
      {renderAxisLabel()}
    </g>
  );
};




export { LeftAxis, BottomAxis, TopAxis, calculateNiceTickSize, calculateDateTickSize };