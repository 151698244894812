import React, { createContext, useContext, useCallback, useRef, useState, useEffect } from 'react';
import { loadCSVData, transformData } from './loadCSVData';
import _ from 'lodash';
import BarChart from './BarChart'; 
import LineChart from './LineChart';
import ScatterPlot from './ScatterPlot2';
import Label from './Label';
import Table from './Table';
import Details from './Details';
import RadarChart from './RadarChart';
import QRCodeComponent from './QRCodeComponent';
import MemoryUsage from './MemoryUsage';
import DropdownSelector from './DropdownSelector';
import SpeechBox from './SpeechBox';
import { Rnd } from "react-rnd";
//import './JsonDashboard.css'; // Import the CSS for styling

import { filterTableByColumnValues, aggregateTableData, aggregateTableDataNew, processTable, sortTable, aggregateTableDataWithTiers } from './TableOperations';


//https://github.com/bokuweb/react-rnd

class ChannelManager {
  constructor() {
      this.channelValues = {};
      this.publishInfo = {};
      this.subscribeInfo = {};
      this.chartIdSubscriptions = {};
  }

  publish({ channelName, values }) {
      this.channelValues[channelName] = values;
  }

  subscribe({ channelName, chartId }) {
      const key = `${channelName}#${chartId}`;
      const subscriptionDetails = this.subscribeInfo[key] || [];
      const values = this.channelValues[channelName] || []; // Fetch current values for the channel
      return { subscriptionDetails, values };
  }

  registerPublish({ channelName, chartId, columnName }) {
      const key = `${channelName}#${chartId}`;
      this.publishInfo[key] = { chartId, columnName };
  }

  registerSubscribe({ channelName, chartId, columnName, operator }) {
      const key = `${channelName}#${chartId}`;
      this.subscribeInfo[key] = this.subscribeInfo[key] || [];
      this.subscribeInfo[key].push({ columnName, operator });

      // Register this subscription under the chartId for easy retrieval
      this.chartIdSubscriptions[chartId] = this.chartIdSubscriptions[chartId] || [];
      this.chartIdSubscriptions[chartId].push({ channelName, columnName, operator });
  }

  getSubscriptionsByChartId({ chartId }) {
    const subscriptions = this.chartIdSubscriptions[chartId] || [];
    return subscriptions
      .map(sub => ({
        ...sub,
        values: this.channelValues[sub.channelName] || []
      }))
      .filter(sub => sub.values.length > 0); // This line filters out any subscriptions with an empty values array
  }

  registerFromConfig(config) {
    const { chartId, channels } = config;

    // Check if 'channels' is present and is an array before iterating
    if (Array.isArray(channels)) {
        channels.forEach(channel => {
            if (channel.pubsub === 'publish') {
                this.registerPublish({
                    channelName: channel.channel,
                    chartId: chartId,
                    columnName: channel.columnName
                });
            } else if (channel.pubsub === 'subscribe') {
                this.registerSubscribe({
                    channelName: channel.channel,
                    chartId: chartId,
                    columnName: channel.columnName,
                    operator: channel.operator
                });
            }
        });
    }
  }

}



const PubSubContext = createContext();

export const usePubSub = () => useContext(PubSubContext);

export const PubSubProvider = ( {configs} ) => {

    const configsCopy = _.cloneDeep(configs);
    for (var i = 0; i < configsCopy.length; i++) {
      configsCopy[i]["rawData"] = [];
      configsCopy[i]["ix"] = i;
    }
    const [chartConfigs, setChartConfigs] = useState(configsCopy); // initialChartConfigs is your initial state
    const subscribersRef = useRef({});
    const datasetsRef = useRef({});
    const chartsRef = useRef({});
    const channelManager = new ChannelManager();

    // Function to update a specific chart configuration
    const updateChartConfig = (updatedConfig, chartIndex) => {
      const newConfigs = [...chartConfigs];
      newConfigs[chartIndex] = updatedConfig;
      setChartConfigs(newConfigs);
  };

    const subscribe = useCallback((channel, callback) => {
      if (!subscribersRef.current[channel]) {
        subscribersRef.current[channel] = [];
      }
      subscribersRef.current[channel].push(callback);
  
      return () => {
        subscribersRef.current[channel] = (subscribersRef.current[channel] || []).filter(sub => sub !== callback);
      };
    }, []);
  
    //this is what is called when a chart publishes a message
    const publish = useCallback((channel, data) => { 
      for (const subscriberIx in subscribersRef.current[channel]) {
        channelManager.publish({ channelName: channel, values: data});
        const subscriber_config = subscribersRef.current[channel][subscriberIx]["config"];
        const signal_channel_ix = subscribersRef.current[channel][subscriberIx]["channel_ix"];
        const subscriber_dataset = subscriber_config["data"]["data_source"];
        const original_data = datasetsRef.current[subscriber_dataset];
    
        // Support for both singular and multiple fields and values
        const fieldsConfig = subscriber_config["channels"][signal_channel_ix];
        const isMultipleFields = Array.isArray(fieldsConfig["fields"]);
        const filterFields = isMultipleFields ? fieldsConfig["columnName"] : [fieldsConfig["columnName"]];
        const filterValues = data;
        //let filtered_data = filterTableByColumnValues(original_data, filterFields, filterValues);
        let filters = channelManager.getSubscriptionsByChartId({ chartId: subscriber_config["chartId"] });
        let filtered_data = filterTableByColumnValues(original_data, filters);
    
        if ("aggregate_tiers" in subscriber_config.data && subscriber_config.data.aggregate_tiers.length > 0) {
          // Handle aggregate tiers if necessary
        } else {
          if ("data" in subscriber_config && "aggregate_keys" in subscriber_config.data && subscriber_config.data.aggregate_keys.length > 0) {
            const secondary_key = subscriber_config.data?.secondary_key ?? null;
            const aggregate_types = subscriber_config.data?.aggregate_types ?? null;
            filtered_data = aggregateTableDataNew(filtered_data, subscriber_config.data.aggregate_keys, aggregate_types);
            if (secondary_key !== null) {
              // Handling for secondary key if necessary
            }
          }
    
          const newConfig = chartConfigs[subscriber_config["ix"]];
          if (subscribersRef.current[channel][subscriberIx].additional !== "highlight") {
            newConfig["rawData"] = filtered_data;
          } else {
            const highlightConfig = {"fields": filterFields, "values": data};
            newConfig["highlight"] = highlightConfig;
          }
    
          updateChartConfig(newConfig, subscriber_config["ix"]);
        }
      }
    }, []);

    const registerDataset = useCallback(async (key, url) => {
      if( key in datasetsRef.current) {
        return;
      }
      try {
          var result = await loadCSVData(url);
          datasetsRef.current[key] = result;
          for(var chartConfigIx in chartsRef.current[key]){
            var chartConfig = chartsRef.current[key][chartConfigIx];
            var new_result = result;
            if( "aggregate_tiers" in chartConfig.data && chartConfig.data.aggregate_tiers.length > 0) {
              const aggregate_results = aggregateTableDataWithTiers(result, chartConfig.data.aggregate_tiers);
              console.log("aggregate_results", aggregate_results);
              new_result = aggregate_results;
            }
            else {
              if( "data" in chartConfig && "aggregate_keys" in chartConfig.data && chartConfig.data.aggregate_keys.length > 0) {
                const secondary_key = chartConfig.data?.secondary_key ?? null;
                const aggregate_types = chartConfig.data?.aggregate_types ?? null;
                new_result = aggregateTableDataNew(result, chartConfig.data.aggregate_keys, aggregate_types, secondary_key);
                new_result = processTable(new_result, [{"type": "calculateMinMax"}]);
                if (chartConfig.data.initial_sort) {
                  new_result = sortTable(new_result, chartConfig.data.initial_sort);
                }
              }
            }
            chartConfig["rawData"] = new_result;
            updateChartConfig(chartConfig, chartConfig["ix"]);
          }
          console.log("REGISTER DATASET");
      } catch (error) {
          console.error('Error fetching dataset:', error);
          // Handle errors as needed
      }
    }, []); // Include publish in the dependency array if it's used


    const registerChart = useCallback(async (key, chart_config) => {
      try {
          
          if (!(key in chartsRef.current)) {
            chartsRef.current[key] = [];
          }
          chartsRef.current[key].push(chart_config);
          channelManager.registerFromConfig(chart_config);
          if( "channels" in chart_config ) {
            for(var channel_ix in chart_config.channels) {
              var channel = chart_config.channels[channel_ix];
              if( "subscribe" == channel.pubsub ) {
                if(!(channel["channel"] in subscribersRef.current)) {
                  subscribersRef.current[channel["channel"]] = [];
                }
                subscribersRef.current[channel["channel"]].push(
                    {
                      "config": chart_config,
                      "channel_ix": channel_ix,
                      "additional": channel.additional ?? "default"
                    }
                );
              }
            }
          }
          //console.log(chartsRef.current);
          
      } catch (error) {
          console.error('Error fetching dataset:', error);
          // Handle errors as needed
      }
    }, []); 

    const clearConfiguration = () => {

      setChartConfigs([]);
      //setChartOrder([]);
      
    };


    const contextValue = {
        publish,
        subscribe,
        registerDataset,
        registerChart 
    };

  
    return (
      <PubSubContext.Provider value={contextValue}>
        <div>
          <JsonDashboard configs={chartConfigs} updateConfig={updateChartConfig} />
        </div>
      </PubSubContext.Provider>
    );
  };


  const ChartRenderer = ({ config }) => {
    const { registerConfigs, registerChart } = usePubSub();
    useEffect(() => {
        const newConfig = registerChart(config.data["data_source"], config);
      }, [config, registerChart]);


    switch (config.chartType) {
        case 'bar':
            return <BarChart  {...config} />;
        case 'line':
            return <LineChart {...config} />;
        case 'scatter':
            return <ScatterPlot {...config} />;
        case 'radar':
            return <RadarChart {...config} />;
        case 'label':
            return <Label {...config} />;
        case 'table':
            return <Table {...config} />;
        case 'details':
          return <Details  {...config} />;
        case 'memory_usage':
            return <MemoryUsage {...config}/>;
        case 'dropdown':
          return <DropdownSelector {...config}/>;
        case 'speech':
          return <SpeechBox {...config}/>;
        default:
            return null;
    }
};

const getDeviceOrientation = () => {
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const isLandscape = window.matchMedia("(orientation: landscape)").matches;
  const isDesktop = window.matchMedia("(min-width: 1024px)").matches; // Adjust the width threshold as needed

  if (isDesktop) {
    return "desktop";
  } else if (isPortrait) {
    return "portrait";
  } else if (isLandscape) {
    return "landscape";
  } else {
    return "normal"; // Fallback or additional handling if needed
  }
};

//const JsonDashboard = ({ configs, updateConfig }) => {
const JsonDashboard = ({ configs }) => {
  const { registerDataset } = usePubSub();
  const [orientation, setOrientation] = useState(getDeviceOrientation());
  const [chartConfigs, setChartConfigs] = useState(configs);
  const [chartOrder, setChartOrder] = useState(configs.map((_, index) => index));
  const [enableResizing, setEnableResizing] = useState({
    top: false, right: false, bottom: false, left: false,
    topRight: true, bottomRight: true, bottomLeft: true, topLeft: true,
  });
  const rndRef = useRef();
  //const chartConfigs = configs;

  //setChartConfigs(configs);

  

  useEffect(() => {
		const handleResizeOrientation = () => {
      const orientation2 = getDeviceOrientation();
      console.log("handleResizeOrientation", orientation2);
			setOrientation(orientation2);
      window.location.reload();
		};

		///window.addEventListener('resize', handleResizeOrientation);
		window.addEventListener('orientationchange', handleResizeOrientation);

		return () => {
			//window.removeEventListener('resize', handleResizeOrientation);
			window.removeEventListener('orientationchange', handleResizeOrientation);
      
		};
	}, []);

  const handleWheelScroll = (event) => {
    return;
    setChartOrder(prevOrder => {
        // Copy the current order, excluding the first (docked) chart
        const movableCharts = prevOrder.slice(1);
        
        if (event.deltaY < 0) {
            // Move the first movable chart to the end
            const firstElement = movableCharts.shift();
            movableCharts.push(firstElement);
        } else {
            // Move the last movable chart to the start
            const lastElement = movableCharts.pop();
            movableCharts.unshift(lastElement);
        }

        // Return the new order, including the docked chart
        return [prevOrder[0], ...movableCharts];
    });
  };

  
  const registerDatasets = () => {
    // Create a set of unique data sources required by the app
    const dataSources = new Set(configs.map(config => config.data.data_source));


    // Environment variable for the server URL
    const serverUrl = process.env.REACT_APP_EXPRESS_URL;

    // Register datasets based on their presence in the configuration
    if (dataSources.has('nfl_defense')) {
      registerDataset('nfl_defense', `${serverUrl}/nfl_defense.csv`);
    }
    if (dataSources.has('nfl_combined')) {
      registerDataset('nfl_combined', `${serverUrl}/nfl_combined.csv`);
    }
    if (dataSources.has('ticker')) {
      registerDataset('ticker', `${serverUrl}/ticker.csv`);
    }
    if (dataSources.has('nba')) {
      registerDataset('nba', `${serverUrl}/nba.csv`);
    }
    if (dataSources.has('business')) {
      registerDataset('business', `${serverUrl}/business.csv`);
    }
  }; 
  registerDatasets();

  const saveConfiguration = () => {
    // Prompt the user for a name
    const name = window.prompt("Please enter a name for the configuration:");
  
    // Ensure the user entered a name
    if (name) {
      // Convert the config array to a JSON string
      const configJson = JSON.stringify(configs);
  
      // Save it to local storage using the name provided by the user
      localStorage.setItem(name, configJson);
      console.log("Configuration saved under the name:", name);
    } else {
      // Handle the case where the user pressed Cancel or entered an empty name
      console.log("Save operation cancelled or no name entered.");
    }
  };

  const uploadConfiguration = () => {
    // Prompt the user for a name
    const name = window.prompt("Please enter a name for the configuration:");
  
    // Ensure the user entered a name
    if (name) {
      // Copy the configs array and modify the copy
      const modifiedConfigs = configs.map(config => {
        // Return a new object for each config, changing rawData to an empty object
        return { ...config, rawData: {} };
      });
  
      // Convert the modified config array to a JSON string
      const configJson = JSON.stringify(modifiedConfigs);
  
      // Create a Blob from the JSON string
      const configBlob = new Blob([configJson], { type: 'application/json' });
      // Create FormData and append the file
      const formData = new FormData();
      formData.append('file', configBlob, `${name}.json`);
  
      // Send a POST request to the server with the FormData
      fetch('http://localhost:3001/upload-json', {
        method: 'POST',
        body: formData,
      })
      .then(response => {
        //console.log(response);
      })
      .then(data => {
        ///console.log("Configuration uploaded successfully:", data);
      })
      .catch(error => {
        //console.error("Error uploading configuration:", error);
      });
  
    } else {
      // Handle the case where the user pressed Cancel or entered an empty name
      ////console.log("Save operation cancelled or no name entered.");
    }
  };

  

  const handleResizeStop = (identifier) => (e, direction, ref, delta, position) => {
    const newWidth = ref.offsetWidth;
    const newHeight = ref.offsetHeight;
    
    //updateConfigs(identifier, { width: newWidth, height: newHeight, x: position.x, y: position.y });
    configs[identifier]["app"]["width"] = newWidth;
    configs[identifier]["app"]["height"] = newHeight;
  }

  const handleMouseDown = (e) => {
    const target = rndRef.current;
    if (!target) return;

    const bounds = target.resizableElement.current.getBoundingClientRect();
    const posY = e.clientY - bounds.top; // For touch events, use e.touches[0].clientY

    // Check if click is in the top 5%
    if (posY / bounds.height <= 0.05) {
      // Enable dragging by setting a specific drag handle class
      target.props.dragHandleClassName = 'custom-drag-handle';
    } else {
      // Disable dragging
      target.props.dragHandleClassName = '';
    }

    // Corner resize is enabled by default, but you can add additional logic here if needed
  };

  const handleDragStop = (identifier) => (e, d) => {
    // d is the drag event data, d.x and d.y are the new positions
    configs[identifier]["app"]["x"] = d.x;
    configs[identifier]["app"]["y"] = d.y;
    // Similarly, ensure configs changes trigger a re-render as needed
  };

  const handleTouchStart = (e) => {
    e.preventDefault(); // Prevent default scroll behavior
    // Additional touch start logic here, if needed
  };

  const resizeHandleStyles = {
    bottom: { cursor: 'default' },
    bottomLeft: { cursor: 'default' },
    bottomRight: { cursor: 'default' },
    left: { cursor: 'default' },
    right: { cursor: 'default' },
    top: { cursor: 'default' },
    topLeft: { cursor: 'default' },
    topRight: { cursor: 'default' },
  };




  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#f0f0f0",
    cursor: 'default'
  };

  const defaultSize = {
    x: 0,
    y: 0,
    width: 350,
    height: 350,
  };

  const clearConfiguration = () => {

    setChartConfigs([]);
    setChartOrder([]);
    
  };

  


  const getSizeFromConfigOrDefault = (config, index, layoutConfig) => {
    const { app } = config;

    console.log("ES ORIENTATION", orientation);
    
    // Calculate default positions here, to use if no config is provided
    if( "desktop" == orientation) {
      const defaultPosition = calculatePosition(index, layoutConfig);
    
      const retDefault = {
        x: app?.x ?? defaultPosition.x,
        y: app?.y ?? defaultPosition.y,
        width: app?.width ?? layoutConfig.chartWidth,
        height: app?.height ?? layoutConfig.chartHeight,
      };
    
      return { retDefault };
    }
    else if( "portrait" == orientation ){
      const mobileLayoutConfig = [
        { type: 'full', count: 2 }, // First 2 charts are full width and height
        { type: 'half', count: 2 }, // Next 2 charts are half width and height, placed in one row
        { type: 'full', count: 1 }, // Next chart is full width and height
        { type: 'half', count: 10 }, // Following 4 charts are half width and height, placed in two rows
        // Add more configurations as needed
      ];
      const positions = calculateMobilePositions(mobileLayoutConfig);
      console.log("positions", positions);
      const retDefault = {
        x: positions[index].x,
        y: positions[index].y,
        width: positions[index].width,
        height: positions[index].height,
      };
    
      return { retDefault };
    }
    else if( "landscape" == orientation ){
      const mobileLayoutConfig = [
        { type: 'full', count: 2 }, // First 2 charts are full width and height
        { type: 'half', count: 2 }, // Next 2 charts are half width and height, placed in one row
        { type: 'full', count: 1 }, // Next chart is full width and height
        { type: 'half', count: 10 }, // Following 4 charts are half width and height, placed in two rows
        // Add more configurations as needed
      ];
      const positions = calculateHorizontalMobilePositions(mobileLayoutConfig);
      console.log("positions", positions);
      const retDefault = {
        x: positions[index].x,
        y: positions[index].y,
        width: positions[index].width,
        height: positions[index].height,
      };
      console.log("retDefault orientation", retDefault);
      return { retDefault };
    }
  };
  

  const calculatePosition = (index, layoutConfig) => {
    const row = Math.floor(index / layoutConfig.columns);
    const column = index % layoutConfig.columns;
    const x = (layoutConfig.chartWidth + layoutConfig.margin) * column;
    const y = (layoutConfig.chartHeight + layoutConfig.margin) * row;
    return { x, y };
  };

  const calculateMobilePositions = (layoutConfig) => {
    const positions = [];
    let index = 0;
    let x = 0;
    let y = 0;
    const margin = 10; // Assuming a margin of 10

    layoutConfig.forEach((layout) => {
        const { type, count } = layout;
        const isFull = type === 'full';
        const chartWidth = isFull ? 300 : 150;
        const chartHeight = isFull ? 300 : 150;

        for (let i = 0; i < count; i++) {
            positions.push({ x, y, width: chartWidth, height: chartHeight });
            if (isFull) {
                // Move to the next row for full-width charts
                y += chartHeight + margin;
            } else {
                // For half-width charts, move to the next column or row
                if ((index % 2) === 1) {
                    // Move to the next row after every 2 half-width charts
                    x = 0;
                    y += chartHeight + margin;
                } else {
                    // Move to the next column
                    x += chartWidth + margin;
                }
            }
            index++;
        }
        // Reset x to 0 after each configuration block
        x = 0;
        if (!isFull && count % 2 !== 0) {
            // If the last row in a half-width block is incomplete, move to the next row
            y += chartHeight + margin;
        }
    });

    return positions;
};

const calculateHorizontalMobilePositions = (layoutConfig) => {
	const positions = [];
	let x = 0;
	let y = 0;
	const margin = 10; // Assuming a margin of 10

	layoutConfig.forEach((layout) => {
		const { type, count } = layout;
		const isFull = type === 'full';
		const chartWidth = isFull ? 300 : 150;
		const chartHeight = isFull ? 300 : 150;

		for (let i = 0; i < count; i++) {
			positions.push({ x, y, width: chartWidth, height: chartHeight });

			// Move to the next row for half-width charts
			if (!isFull) {
				y += chartHeight + margin;

				// Move to the next column after every 2 charts
				if ((i + 1) % 2 === 0) {
					y = 0; // Reset y to 0
					x += chartWidth + margin; // Move to the next column
				}
			} else {
				// For full-width charts, reset y and move to the next column after each chart
				y = 0; // Reset y to 0
				x += chartWidth + margin; // Move to the next column
			}
		}

		// Reset y to 0 after each configuration block
		y = 0;
		if (!isFull && count % 2 !== 0) {
			// If the last column in a half-width block is incomplete, move to the next column
			x += chartWidth + margin;
		}
	});

	return positions;
};


  
  const layoutConfig = {
    columns: 5,
    margin: 10,
    chartWidth: 350,
    chartHeight: 350,
  };

  const dragHandleStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '15%', // Adjust as necessary
    cursor: 'move',
    backgroundColor: 'rgba(204, 204, 204, 0.5)',
    zIndex: 10 // Ensure it overlays content
  };

  const shouldHideConfigButtons = window.location.pathname.includes('/dash/');

  return (
      <div className="dashboard-container">
        {/* Docked chart */}
        
       

        {/* Scrollable container for other charts */}
        <div className="scrollable-container" onWheel={handleWheelScroll}>
          {chartOrder.map((orderIndex, index) => {
            // Perform destructuring outside of the JSX return statement
            //const { retPos: position, retSize: size, retDefault: adefault }  = getSizeFromConfigOrDefault(chartConfigs[orderIndex]);
            
            //const position = calculatePosition(index+1, layoutConfig);
            //const { retDefault: adefault } = getSizeFromConfigOrDefault(chartConfigs[orderIndex]);
            ///const updatedDefault = { ...adefault, ...position, width: layoutConfig.chartWidth, height: layoutConfig.chartHeight };
            console.log("drawin with orientation", orientation);
            const { retDefault } = getSizeFromConfigOrDefault(chartConfigs[orderIndex], index, layoutConfig);
            chartConfigs[orderIndex]["app"]["width"] = retDefault.width;
            chartConfigs[orderIndex]["app"]["height"] = retDefault.height;
            chartConfigs[orderIndex]["app"]["x"] = retDefault.x;  
            chartConfigs[orderIndex]["app"]["y"] = retDefault.y;
            console.log("orientation", chartConfigs[orderIndex]);
            return (
                <Rnd
                    style={style}
                    onResizeStop={handleResizeStop(orderIndex)}
                    onDragStop={handleDragStop(orderIndex)}
                    onMouseDown={handleMouseDown}
                    onTouchStart={handleTouchStart}
                    default={retDefault}
                    enableResizing={enableResizing}
                    dragHandleClassName="drag-handle"
                    className={`chart ${index === 0 ? 'chart-fixed' : ''}`}
                >
                    {/* Floating Drag Handle */}
                    <div className="drag-handle" style={dragHandleStyle}>
                      {/* This area is the draggable handle */}
                    </div>

                    {/* The rest of the component content, now unaffected by the drag handle's presence */}
                    <ChartRenderer config={chartConfigs[orderIndex]} />
                </Rnd>
             
              
            );
          })}
        </div>
        {!shouldHideConfigButtons && (
          <Rnd
            dragHandleClassName="drag-handle"
          >
          <div className="drag-handle" style={dragHandleStyle}>           
          </div>
          <br></br>
          <button className="button" onClick={saveConfiguration}>Json Dashboard Save</button>
          <button className="button" onClick={uploadConfiguration}>Json Dashboard Upload</button>
          </Rnd>
        )}
      </div>
    );
};


/*
      <div className="dashboard-container">
          <QRCodeComponent text="https://www.example.com" />
          {chartConfigs.map((config, index) => (
              <div className="chart-container" key={index}>
                  <ChartRenderer config={config} />
              </div>
          ))}
      </div>
*/