import React, { useEffect, useState, memo } from 'react';
import { usePubSub } from './PubSubContext'; // Assuming you have created a PubSubContext

const Label = memo(({ rawData,  channels = [], columns_to_include=[], }) => {
    const [labelValue, setLabelValue] = useState(''); // Local state to store the received value

    var new_label_value = "";
    if( rawData.headers && rawData.rows && rawData.rows.length > 0){
        const labelIndex = rawData.headers.indexOf(columns_to_include[0]);
        if (labelIndex === -1) {
            throw new Error('One or more keys not found in headers');
        }
        new_label_value = rawData.rows[0][labelIndex];
    }
    
    return <div className="label">{new_label_value}</div>; // Display the label value
}, areEqual);

function areEqual(prevProps, nextProps) {
    return prevProps.rawData === nextProps.rawData;
}

export default Label;