import React, { useState, useEffect, useRef, useCallback, useMemo} from 'react';
import './ScatterPlot.css';
import { usePubSub } from './PubSubContext';

import { BottomAxis, LeftAxis, TopAxis, calculateNiceTickSize, calculateMaxValue } from './Axis';

// Helper function
function prepareDataForScatterPlot(data, labelKey, xKey, yKey) {
    if( data == [] || data.length == 0 ){
        return [];
    } 
    if (!data || !Array.isArray(data.headers) || !Array.isArray(data.rows)) {
        console.log("ERROR",data);
        throw new Error('Invalid data format. Expected format: {"headers": [..], "rows": [[..]]}');
    }
    console.log("scatter data", data);


    const labelIndex = data.headers.indexOf(labelKey);
    const xIndex = data.headers.indexOf(xKey);
    const yIndex = data.headers.indexOf(yKey);

    if (labelIndex === -1 || xIndex === -1 || yIndex === -1) {
        throw new Error('One or more keys not found in headers');
    }

    return data.rows
        .map(row => ({
            label: row[labelIndex],
            x: row[xIndex],
            y: row[yIndex]
        }))
        .filter(point => {
            // Define what you consider as valid for x and y values
            const isValid = (value) => value !== undefined && value === value && value !== null;
            return isValid(point.x) && isValid(point.y);
        });
}

function useCustomState(initialValue, channels) {
    const { publish } = usePubSub();
    const [values, setValues] = useState(initialValue);
    const prevValuesRef = useRef(); // useRef to keep track of the previous values

    useEffect(() => {
        // Get the current labels from the values array
        const currentLabels = values.map(value => value.label);
        
        // Convert arrays to strings to compare
        if (JSON.stringify(prevValuesRef.current) !== JSON.stringify(currentLabels)) {
            console.log("Values changed from", prevValuesRef.current, "to", currentLabels);

            // Execute publish logic for each channel configuration
            channels.forEach(channelConfig => {
                if (channelConfig.pubsub === 'publish') {
                    publish(channelConfig.channel, currentLabels);
                }
            });

            // Update the ref to the current labels for the next render
            prevValuesRef.current = currentLabels;
        }
    }, [values, channels, publish]); // Dependencies: values, channels, publish

    return [values, setValues];
}

const mean = (arr) => arr.reduce((sum, val) => sum + val, 0) / arr.length;

// Helper function to calculate median
const median = (arr) => {
	const sorted = [...arr].sort((a, b) => a - b);
	const mid = Math.floor(sorted.length / 2);
	return sorted.length % 2 === 0 ? (sorted[mid - 1] + sorted[mid]) / 2 : sorted[mid];
};

function cleanFilename(label) {
    if( label === undefined ){
        return "";
    }
    return label.replace(/[^a-zA-Z0-9 ]/g, '').replace(/ /g, '_');
}

const generatePaths = (table, template) => {
    // Check if the table has the necessary properties
    if (!("headers" in table) || !("rows" in table) || !table.rows.length) {
        return [];
    }

    console.log("table", table);

    const headers = table.headers;
    const rows = table.rows;
    const pathArray = [];

    // Extract placeholders from the template
    // This uses a regular expression to find all {header} patterns in the template
    const matches = template.match(/{[^}]+}/g) || [];
    // Clean them up to get the actual header names (remove the braces)
    const placeholders = matches.map((match) => match.replace(/[{}]/g, ""));

    // Iterate through each row in the table
    rows.forEach((row) => {
        const currentURL = window.location.origin;
        let result = `${currentURL}/test_icons/${template}`;
        console.log("result", result);

        // Replace only placeholders that are found in the template
        placeholders.forEach((header) => {
            const headerIndex = headers.indexOf(header); // Get the index of the header in the headers array
            if (headerIndex !== -1) {
                const regex = new RegExp(`{${header}}`, 'g'); // Create a regex for replacement
                result = result.replace(regex, cleanFilename(row[headerIndex])); // Replace with cleaned filename
            }
        });

        // Add the final path to the path array
        pathArray.push(result);
    });

    return pathArray;
};

const ScatterPlot = ({ rawData, app={}, channels=[], data={}, highlight={} }) => {
    const [pointData, setPointData] = useState([]);
    const [hoveredNodes, setHoveredNodes] = useCustomState([], channels);
    const [isReady, setIsReady] = useState(false);
    const { width, height, showAverageLines } = app;
    const [isFrozen, setIsFrozen] = useState(false);
    const [globalMinMax, updateGlobalMinMax] = useState({
        globalXMin: null,
        globalXMax: null,
        globalYMin: null,
        globalYMax: null,
    });
    const calculateMode = "median";

    
    // Padding for the axes
    const leftPad = 60;
    const rightPad = 40;
    const topPad = 50;
    const bottomPad = 40;

    useEffect(() => {
        const processedData = prepareDataForScatterPlot(rawData, data["labels"][0], data["columns_to_use"][0], data["columns_to_use"][1]);
        setPointData(processedData);
        setIsReady(true);
    }, [rawData, width, height, data]);

    const xValues = pointData.map((point) => point.x);
	const yValues = pointData.map((point) => point.y);


    const centralX = useMemo(
		() => (calculateMode === 'mean' ? mean(xValues) : median(xValues)),
		[xValues, calculateMode]
	);
	const centralY = useMemo(
		() => (calculateMode === 'mean' ? mean(yValues) : median(yValues)),
		[yValues, calculateMode]
	);

    

    const imgFilePaths = useMemo(() => {
        return data.point_image_template
            ? generatePaths(rawData, data.point_image_template)
            : [];
    }, [rawData, data.point_image_template]);

	// Classify points into quadrants
	const quadrants = useMemo(() => {
		const upperRight = [];
		const upperLeft = [];
		const lowerRight = [];
		const lowerLeft = [];

		pointData.forEach((point, index) => {
			const imagePath = imgFilePaths[index];

			if (point.x >= centralX && point.y >= centralY) {
				upperRight.push({ ...point, imagePath });
			} else if (point.x < centralX && point.y >= centralY) {
				upperLeft.push({ ...point, imagePath });
			} else if (point.x >= centralX && point.y < centralY) {
				lowerRight.push({ ...point, imagePath });
			} else {
				lowerLeft.push({ ...point, imagePath });
			}
		});

		return { upperRight, upperLeft, lowerRight, lowerLeft };
	}, [pointData, imgFilePaths, centralX, centralY]);


    
    const calculateAxisScales = (pointData, useGlobalMinMax = true) => {
        let xValues = pointData.map(d => d.x);
        let yValues = pointData.map(d => d.y);
    
        let xMin = Math.min(...xValues);
        let xMax = Math.max(...xValues);
        let yMin = Math.min(...yValues);
        let yMax = Math.max(...yValues);

        if( yMax > globalMinMax.globalYMax ){
            globalMinMax.globalYMax = yMax;
        }
        if( yMax > globalMinMax.globalXMax ){
            globalMinMax.globalXMax = xMax;
        }

        if (useGlobalMinMax) {
            xMax = globalMinMax.globalXMax !== null ? globalMinMax.globalXMax : xMax;
            yMax = globalMinMax.globalYMax !== null ? globalMinMax.globalYMax : yMax;
        }

        
    
        // Use calculateNiceTickSize to refine these values
        const { ticks: xTicks, endTick: xEndTick } = calculateNiceTickSize(xMin, xMax, 5);
        const { ticks: yTicks, endTick: yEndTick } = calculateNiceTickSize(yMin, yMax, 5);
    
        // Adjusted to use endTick as the definitive min/max
        xMin = xMin; // Assuming you always want to start from 0 or adjust as needed
        xMax = xEndTick;
        yMin = yMin; // Assuming you always want to start from 0 or adjust as needed
        yMax = yEndTick;
    
        return {
            xScale: d => (d - xMin) / (xMax - xMin) * (width - leftPad - rightPad) + leftPad,
            yScale: d => height - bottomPad - (d - yMin) / (yMax - yMin) * (height - topPad - bottomPad),
            xTicks,
            yTicks
        };
    };

    const { xScale, yScale, xTicks, yTicks } = calculateAxisScales(pointData);

    const dynamicXScale = xScale ? xScale : () => 0;
    const dynamicYScale = yScale ? yScale : () => 0;


    const handleInteraction = (offsetX, offsetY) => {
        if (isFrozen) return; // Skip processing if frozen
    
        const thresholdDistance = 20; // distance in pixels within which points will be considered
        const pointsInRange = [];
        let closestPoint = null;
        let minDistance = Infinity;
    
        pointData.forEach(point => {
            const dx = offsetX - xScale(point.x);
            const dy = offsetY - yScale(point.y);
            const distance = Math.sqrt(dx * dx + dy * dy);
            if (distance < minDistance) {
                minDistance = distance;
                closestPoint = { ...point, dispx: offsetX, dispy: offsetY };
            }
    
            if (distance <= thresholdDistance) {
                pointsInRange.push({ ...point, dispx: offsetX, dispy: offsetY });
            }
        });
    
        if (pointsInRange.length === 0 && closestPoint) {
            pointsInRange.push(closestPoint);
        }
    
        // Process the pointsInRange as needed
        setHoveredNodes(pointsInRange);
    };

    // Mouse event handler
    const handleMouseMove = (event) => {
        event.preventDefault(); // Prevent scrolling and bouncing
        const { offsetX, offsetY } = event.nativeEvent;
        handleInteraction(offsetX, offsetY);
    };

    // Touch event handler
    /*
    const handleTouchMove = (event) => {
        event.preventDefault(); // Prevent scrolling and bouncing
        const touch = event.touches[0];
        //const offsetX = touch.clientX - touch.target.getBoundingClientRect().left;
        //const offsetY = touch.clientY - touch.target.getBoundingClientRect().top;
        const offsetX = touch.clientX;
        const offsetY = touch.clientY;
        handleInteraction(offsetX, offsetY);
    };
    */
    const handleTouchMove = (event) => {
        event.preventDefault(); // Prevent scrolling and bouncing
        const touch = event.touches[0];
        const parentRect = event.currentTarget.getBoundingClientRect(); // or another parent if needed
        const offsetX = touch.clientX - parentRect.left;
        const offsetY = touch.clientY - parentRect.top;
        handleInteraction(offsetX, offsetY);
    };

    const handleClick = () => {
        setIsFrozen(true);
        setTimeout(() => setIsFrozen(false), 1000); // Re-enable after 1 second
    };

    

    
    const img_dim = 20;
    

    return (
        <div className="scatter-plot" style={{ width: width, height: height, position: 'relative', touchAction: 'none' }}>
            {isReady && (
                <svg 
                    width={width} 
                    height={height} 
                    onMouseMove={handleMouseMove} 
                    onTouchMove={handleTouchMove} 
                    onClick={handleClick}
                >
                    <rect
                        x={0}
                        y={0}
                        width={dynamicXScale(centralX)}
                        height={dynamicYScale(centralY)}
                        fill="lightblue"
                        opacity={1.0}
                    />
                    <rect
                        x={dynamicXScale(centralX)}
                        y={0}
                        width={dynamicXScale(centralX)}
                        height={dynamicYScale(centralY)}
                        fill="lightgreen"
                        opacity={1.0}
                    />
                    <rect
                        x={0}
                        y={dynamicYScale(centralY)}
                        width={dynamicXScale(centralX)}
                        height={height - dynamicYScale(centralY)}
                        fill="lightcoral"
                        opacity={1.0}
                    />
                    <rect
                        x={dynamicXScale(centralX)}
                        y={dynamicYScale(centralY)}
                        width={width - dynamicXScale(centralX)}
                        height={height - dynamicYScale(centralY)}
                        fill="lightyellow"
                        opacity={1.0}
                    />
                    <BottomAxis
                        orient="bottom"
                        ticks={xTicks}
                        tickSize={10}
                        width={width}
                        height={height}
                        leftPadding={leftPad}
                        rightPadding={rightPad}
                        bottomPadding={bottomPad}
                        tickFormat={value => value.toString()}
                        axisLabel={data["columns_to_use"][0]}
                        valueInThousands={false}
                    />
                    <LeftAxis
                        orient="left"
                        ticks={yTicks}
                        tickSize={10}
                        width={width}
                        height={height}
                        leftPadding={leftPad}
                        bottomPadding={bottomPad}
                        tickFormat={value => value.toString()}
                        axisLabel={data["columns_to_use"][1]}
                        valueInThousands={false}
                    />
                    {data.title && data.title.trim() !== "" && (
                        <TopAxis
                            width={width}
                            tickFormat={value => value.toString()}
                            leftPadding={leftPad}
                            topPadding={topPad} // You might need to adjust or add this prop based on your TopAxis component props
                            axisLabel={data.title}
                        />
                    )}
                   
                    
                   {pointData.map((point, index) => {
                        // Determine the image path for this point, if available.
                        const imagePath = imgFilePaths[index]; // Will be undefined if filePaths is empty or doesn't contain enough items.

                        return (
                            <g key={index} transform={`translate(${dynamicXScale(point.x)}, ${dynamicYScale(point.y)})`}>
                                <circle cx={0} cy={0} r={5} fill="#009879" />
                                {/* Conditionally render the image if imagePath is valid */}
                                {imagePath ? (
                                    <image
                                        href={imagePath}
                                        x={-img_dim/2} // Adjust x position to center the image
                                        y={-img_dim/2} // Adjust y position to center the image
                                        width={img_dim}
                                        height={img_dim}
                                    />
                                ) : (
                                    // If no imagePath, we only draw the circle (or other fallback content)
                                    <circle cx={0} cy={0} r={0} fill="#ff0000" /> // A larger circle in place of an image

                                )}
                                <text
                                    x={0} // Text horizontal position
                                    y={-10} // Text vertical position, adjusted relative to the circle
                                    textAnchor="middle"
                                    alignmentBaseline="central"
                                    fontSize="10"
                                    fill="black"
                                >
                                    {point.label}
                                </text>
                            </g>
                        );
                    })}
                    {hoveredNodes.map((hoveredPoint, index) => (
                        <g key={index} transform={`translate(${dynamicXScale(hoveredPoint.x)}, ${dynamicYScale(hoveredPoint.y)})`}>
                            <circle cx={0} cy={0} r={3} fill="yellow" />
                        </g>
                    ))}
                </svg>
            )}
            {hoveredNodes.length > 0 && (
                <div
                    style={{
                        position: 'fixed',
                        backgroundColor: '#333',
                        color: 'white',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        pointerEvents: 'none',
                        left: `${hoveredNodes[0].dispx}px`, // Position based on the first node
                        top: `${hoveredNodes[0].dispy}px`
                    }}
                >
                    {hoveredNodes.length > 4 && (
                        <React.Fragment>
                            {hoveredNodes.length} points selected <br />
                        </React.Fragment>
                    )}
                    {hoveredNodes.slice(0, 19).map((node, index) => (
                        <React.Fragment key={index}>
                            {node.label}{index < hoveredNodes.length - 1 && <br />}
                        </React.Fragment>
                    ))}
                    {hoveredNodes.length > 20 && (  // Add ellipsis if more than 20 nodes are selected
                        <span>...</span>
                    )}
                </div>
            )}
        </div>
    );
};


export default ScatterPlot;
