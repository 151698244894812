  export const filterTableByColumnValue = (table, columnName, filterValue) => {
    if (!table || !table.headers || !table.rows || table.rows.length === 0) {
        return { headers: [], rows: [] };
    }

    if (filterValue === 'No filter') {
        return table;
    }

    // Find the index of the column to filter by
    const columnIndex = table.headers.indexOf(columnName);
    if (columnIndex === -1) {
        console.error("Column not found");
        return table; // Return the original table if the column is not found
    }

    // Check if filterValue is an array and set the filter condition accordingly
    const isFilterArray = Array.isArray(filterValue);
    
    // Filter the rows based on whether filterValue is an array or not
    const filteredRows = table.rows.filter(row => {
        return isFilterArray ? filterValue.includes(row[columnIndex]) : row[columnIndex] === filterValue;
    });

    return {
        headers: table.headers,
        rows: filteredRows
    };
};

/*
export const filterTableByColumnValues = (table, columnNames, filterValues) => {
	if (!table || !table.headers || !table.rows || table.rows.length === 0) {
		return { headers: [], rows: [] };
	}

	// Early return if no filtering is needed
	if (filterValues.includes('No filter')) {
		return table;
	}

	// Find the indexes of the columns to filter by
	const columnIndexes = columnNames.map(name => table.headers.indexOf(name)).filter(index => index !== -1);

	if (columnIndexes.length === 0) {
		console.error("Columns not found");
		return table; // Return the original table if no valid columns are found
	}

    console.log("PUBLISH trying to filter", columnIndexes, filterValues);

	// Filter rows by checking each column's value against the corresponding filter value
	const filteredRows = table.rows.filter(row => {
		return columnIndexes.every((index, i) => {
			const isFilterArray = Array.isArray(filterValues[i]);
			return isFilterArray ? filterValues[i].includes(row[index]) : row[index] === filterValues[i];
		});
	});

	return {
		headers: table.headers,
		rows: filteredRows
	};
};
*/
export const filterTableByColumnValues = (table, columnFilters) => {
	if (!table || !table.headers || !table.rows || table.rows.length === 0) {
		return { headers: [], rows: [] };
	}

	// Early return if no filtering is needed
	if (columnFilters.some(filter => filter.values.includes('No filter'))) {
		return table;
	}

	const filteredRows = table.rows.filter(row => {
		return columnFilters.every(filter => {
			const columnIndex = table.headers.indexOf(filter.columnName);
			if (columnIndex === -1) return true; // Skip if column not found

			const rowValue = row[columnIndex];
			switch (filter.operator) {
				case 'equals':
					return Array.isArray(filter.values) ? filter.values.includes(rowValue) : rowValue === filter.values;
				case 'year':
					return new Date(rowValue).getFullYear() === parseInt(filter.values);
				case 'monthYear':
					const rowDate = new Date(rowValue);
					const [filterYear, filterMonth] = filter.values.split('-').map(Number);
					return rowDate.getFullYear() === filterYear && rowDate.getMonth() === filterMonth - 1;
				case 'quarterYear':
					const quarter = Math.floor(new Date(rowValue).getMonth() / 3) + 1;
					return quarter === parseInt(filter.values.split('Q')[1]) && new Date(rowValue).getFullYear() === parseInt(filter.values.split('Q')[0]);
				default:
					console.error("Filter operator not supported:", filter.operator);
					return true;
			}
		});
	});

	return {
		headers: table.headers,
		rows: filteredRows
	};
};


export const aggregateTableData = (table, keyColumns, aggregateColumns, secondaryKey=null) => {
    if (!table || !table.headers || !table.rows || table.rows.length === 0) {
        return { headers: [], rows: [] };
    }

    const keyIndices = keyColumns.map(key => table.headers.indexOf(key)).filter(index => index !== -1);
    const secondaryKeyIndex = secondaryKey ? table.headers.indexOf(secondaryKey) : -1;


    // Process the new structure of aggregateColumns
    const aggregateIndices = aggregateColumns.map(aggCol => {
        const index = table.headers.indexOf(aggCol.field);
        return index !== -1 ? { index, type: aggCol.type, rename: aggCol.rename } : null;
    }).filter(item => item !== null);

    const aggregation = {};
    let secondaryValues = {};

    table.rows.forEach(row => {
        if (keyIndices.some(index => row[index] === undefined)) {
            return; // Skip this row if any key column value is undefined
        }

        const key = keyIndices.map(i => row[i]).join('-');
        const secondaryKeyValue = secondaryKeyIndex !== -1 ? row[secondaryKeyIndex] : null;

        if (!aggregation[key]) {
            aggregation[key] = {
                data: {},
            };
            secondaryValues[key] = {};
            keyIndices.forEach((index, i) => {
                aggregation[key][keyColumns[i]] = row[index];
                if( secondaryKey !== null){
                    aggregation[key].secondaryValues = [];
                }
            });
            aggregateIndices.forEach(({ index, type, rename }) => {
                const value = row[index];
                aggregation[key].data[rename] = (value !== undefined && type !== 'count') ? value : 0;
                secondaryValues[key][rename] = [];
                //if (type === 'count') {
                //    aggregation[key].data[rename] = 1;
                //}
            });
        } //else {
        aggregateIndices.forEach(({ index, type, rename }) => {
            const value = row[index];
            if( value === null ) {
                return;
            }
            if (value !== undefined) {
                switch (type) {
                    case 'sum':
                    case 'average':
                        aggregation[key].data[rename] += value;
                        break;
                    case 'max':
                        if (value > aggregation[key].data[rename]) {
                            aggregation[key].data[rename] = value;
                        }
                        break;
                    case 'count':
                        aggregation[key].data[rename] += 1;
                        break;case 'sum':
                        case 'average':
                            aggregation[key].data[rename] += value;
                            break;
                        case 'max':
                            if (value > aggregation[key].data[rename]) {
                                aggregation[key].data[rename] = value;
                            }
                            break;
                        case 'count':
                            aggregation[key].data[rename] += 1;
                            break;
                }
                if (secondaryKeyValue !== null) {
                    secondaryValues[key][rename].push({ key: secondaryKeyValue, value: value });
                }
            }
        });

        
    });

    if (aggregateColumns.some(aggCol => aggCol.type === 'average')) {
        Object.values(aggregation).forEach(aggItem => {
            aggregateColumns.forEach(aggCol => {
                if (aggCol.type === 'average') {
                    aggItem.data[aggCol.rename] /= table.rows.filter(row => row[table.headers.indexOf(aggCol.field)] !== undefined).length;
                }
            });
        });
    }
    const outputHeaders = [...keyColumns, ...aggregateColumns.map(aggCol => aggCol.rename)];

    const keys = Object.keys(secondaryValues);
    if (keys.length === 1) {
        // Replace the first dictionary with its single child dictionary
        secondaryValues = secondaryValues[keys[0]];
    }


    // Construct the output rows
    const outputRows = Object.values(aggregation).map(aggItem => {
        // Start with the key column values from the aggregation key
        const row = keyColumns.map(key => aggItem[key]);

        // Add aggregated column values from the data
        aggregateColumns.forEach(aggCol => {
            row.push(aggItem.data[aggCol.rename]);
        });

        return row;
    });


    console.log("secondary", secondaryValues);
   
    return {"headers": outputHeaders, "rows": outputRows, "secondaryValues": secondaryValues };
    
};



const getYear = (date) => date.getFullYear().toString();
const getQuarter = (date) => `Q${Math.floor(date.getMonth() / 3) + 1}`;
const getMonth = (date) => date.toLocaleString('default', { month: 'short' });
const getDay = (date) => date.getDate();
const getYearQuarter = (date) => `${date.getFullYear()}-Q${Math.floor(date.getMonth() / 3) + 1}`;
const getYearMonth = (date) => `${date.getFullYear()}-${date.toLocaleString('default', { month: 'short' })}`;


export const aggregateTableDataNew = (table, keyColumns, aggregateColumns, secondaryKey = null) => {
	if (!table || !table.headers || !table.rows || table.rows.length === 0) {
		return { headers: [], rows: [] };
	}

	const keyIndices = keyColumns.map(key => table.headers.indexOf(key.field)).filter(index => index !== -1);
	const secondaryKeyIndex = secondaryKey ? table.headers.indexOf(secondaryKey) : -1;
	const aggregateIndices = aggregateColumns.map(aggCol => {
		const index = table.headers.indexOf(aggCol.field);
		return index !== -1 ? { index, type: aggCol.type, mode: aggCol.mode, rename: aggCol.rename } : null;
	}).filter(item => item !== null);

	let newHeaders = keyColumns.map(key => key.rename);
	aggregateColumns.forEach(col => newHeaders.push(col.rename));

	const aggregation = {};
	let secondaryValues = {};

	table.rows.forEach(row => {
		if (keyIndices.some(index => row[index] === undefined)) {
			return; // Skip this row if any key column value is undefined
		}

		// Construct the key for each row
		const keyParts = keyColumns.map((keyCol, idx) => {
			const index = table.headers.indexOf(keyCol.field);
			const value = row[index];
			if (keyCol.type === 'date') {
				const dateValue = new Date(value);
				switch (keyCol.mode) {
					case 'year':
						return getYear(dateValue);
					case 'quarter':
						return getYearQuarter(dateValue);
					case 'month':
						return getYearMonth(dateValue);
					case 'day':
						return `${dateValue.getFullYear()}-${dateValue.getMonth() + 1}-${dateValue.getDate()}`;
					default:
						return value;
				}
			}
			return value;
		});

		const key = keyParts.join('-');
		const secondaryKeyValue = secondaryKeyIndex !== -1 ? row[secondaryKeyIndex] : null;

		if (!aggregation[key]) {
			aggregation[key] = {
				data: {},
				row: Array(newHeaders.length).fill(null),
				count: 0
			};
			secondaryValues[key] = {};
			keyColumns.forEach((keyCol, i) => {
				aggregation[key].row[newHeaders.indexOf(keyCol.rename)] = keyParts[i];
			});
			aggregateIndices.forEach(({ index, type, rename }) => {
				const value = row[index];
				aggregation[key].data[rename] = (value !== undefined && type !== 'count') ? value : 0;
				if (type === 'count') {
					aggregation[key].data[rename] = 1;
				}
				secondaryValues[key][rename] = [];
			});
		} else {
			aggregateIndices.forEach(({ index, type, rename }) => {
				const value = row[index];
				if (value === null) {
					return;
				}
				if (value !== undefined) {
					switch (type) {
						case 'sum':
						case 'average':
							aggregation[key].data[rename] += value;
							break;
						case 'max':
							if (value > aggregation[key].data[rename]) {
								aggregation[key].data[rename] = value;
							}
							break;
						case 'count':
							aggregation[key].data[rename] += 1;
							break;
					}
					if (secondaryKeyValue !== null) {
						secondaryValues[key][rename].push({ key: secondaryKeyValue, value: value });
					}
				}
			});
		}
		aggregation[key].count += 1;
	});

	const aggregatedRows = Object.values(aggregation).map(agg => {
		aggregateIndices.forEach(({ type, rename }) => {
			if (type === 'average') {
				agg.data[rename] /= agg.count;
			}
			agg.row[newHeaders.indexOf(rename)] = agg.data[rename];
		});
		return agg.row;
	});

    console.log("secondary", secondaryValues);
	return { headers: newHeaders, rows: aggregatedRows, secondaryValues: secondaryValues };
};

export const dataFromColumns = (table) => {
    const { headers, rows } = table;
    const columnData = {};
    const matching_rows = [];

    headers.forEach((header, index) => {
        columnData[header] = rows.map(row => row[index]);
    });

    return columnData;
};

// Function to transform table data based on rows
export const dataFromRows = (table, keyColumn, valueColumns) => {
    const { headers, rows } = table;
    const rowData = {};
    const keyColumnIndex = headers.indexOf(keyColumn);
    const valueColumnIndexes = valueColumns.map(col => headers.indexOf(col));
    const matching_rows = []

    rows.forEach(row => {
        const key = row[keyColumnIndex];
        rowData[key] = valueColumnIndexes.map(index => row[index]);
        //matching_rows.push(row);
    });

    return rowData;
};

export const processTable = (table, operations) => {
    let processedTable = { ...table, maxValues: {}, minValues: {} };

    operations.forEach(operation => {
        if (operation.type === 'aggregate') {
            const aggregateColumns = operation.columns.reduce((acc, col) => {
                acc[col.column] = col.fn;
                return acc;
            }, {});

            processedTable = aggregateTableData(processedTable, [operation.aggregationKey], aggregateColumns);
        } else if (operation.type === 'filter') {
            processedTable = filterTableByColumnValue(processedTable, operation.columnName, operation.filterValue);
        } else if (operation.type === 'calculateMaxMin') {
            const { maxValues, minValues } = calculateMaxMinValues(processedTable);
            processedTable.maxValues = maxValues;
            processedTable.minValues = minValues;
        } else if (operation.type === 'renameHeader') {
            processedTable = renameTableHeader(processedTable, operation.oldHeader, operation.newHeader);
        }
    });

    return processedTable;
};

const augmentTableWithDateInfo = (table, dateColumns) => {
	const newRows = table.rows.map(row => {
		const newRow = [...row];
		dateColumns.forEach(dateCol => {
			const index = table.headers.indexOf(dateCol.field);
			if (index !== -1) {
				const dateValue = new Date(row[index]);
				switch (dateCol.mode) {
					case 'year':
						newRow.push(getYear(dateValue));
						break;
					case 'quarter':
						newRow.push(getYearQuarter(dateValue));
						break;
					case 'month':
						newRow.push(getYearMonth(dateValue));
						break;
					case 'day':
						newRow.push(`${dateValue.getFullYear()}-${dateValue.getMonth() + 1}-${dateValue.getDate()}`);
						break;
				}
			}
		});
		return newRow;
	});

	const newHeaders = [...table.headers];
	dateColumns.forEach(col => {
		switch (col.mode) {
			case 'year':
				newHeaders.push(`${col.field}_year`);
				break;
			case 'quarter':
				newHeaders.push(`${col.field}_quarter`);
				break;
			case 'month':
				newHeaders.push(`${col.field}_month`);
				break;
			case 'day':
				newHeaders.push(`${col.field}_day`);
				break;
		}
	});

	return { headers: newHeaders, rows: newRows };
};

const performAggregation = (table, keyColumns, aggregateColumns, originalRowIds) => {
	const keyIndices = keyColumns.map(key => table.headers.indexOf(key.field)).filter(index => index !== -1);
	const aggregateIndices = aggregateColumns.map(aggCol => {
		const index = table.headers.indexOf(aggCol.field);
		return index !== -1 ? { index, type: aggCol.type, rename: aggCol.rename } : null;
	}).filter(item => item !== null);

	const newHeaders = [...keyColumns.map(col => col.rename), ...aggregateColumns.map(col => col.rename)];
	const aggregation = {};

	table.rows.forEach((row, rowIndex) => {
		if (keyIndices.some(index => row[index] === undefined)) {
			return; // Skip this row if any key column value is undefined
		}

		const keyParts = keyColumns.map((keyCol, idx) => {
			const index = table.headers.indexOf(keyCol.field);
			const value = row[index];
			if (keyCol.type === 'date') {
				const dateValue = new Date(value);
				switch (keyCol.mode) {
					case 'year':
						return getYear(dateValue);
					case 'quarter':
						return getYearQuarter(dateValue);
					case 'month':
						return getYearMonth(dateValue);
					case 'day':
						return `${dateValue.getFullYear()}-${dateValue.getMonth() + 1}-${dateValue.getDate()}`;
					default:
						return value;
				}
			}
			return value;
		});

		const key = keyParts.join('-');

		if (!aggregation[key]) {
			aggregation[key] = {
				data: {},
				row: Array(newHeaders.length).fill(null),
				rowIds: []
			};
			keyColumns.forEach((keyCol, i) => {
				aggregation[key].row[newHeaders.indexOf(keyCol.rename)] = keyParts[i];
			});
			aggregateIndices.forEach(({ index, type, rename }) => {
				const value = row[index];
				aggregation[key].data[rename] = (value !== undefined && type !== 'count') ? value : 0;
				if (type === 'count') {
					aggregation[key].data[rename] = 1;
				}
			});
		} else {
			aggregateIndices.forEach(({ index, type, rename }) => {
				const value = row[index];
				if (value === null) {
					return;
				}
				if (value !== undefined) {
					switch (type) {
						case 'sum':
						case 'average':
							aggregation[key].data[rename] += value;
							break;
						case 'max':
							if (value > aggregation[key].data[rename]) {
								aggregation[key].data[rename] = value;
							}
							break;
						case 'count':
							aggregation[key].data[rename] += 1;
							break;
					}
				}
			});
		}
		aggregation[key].rowIds.push(originalRowIds[rowIndex]);
	});

	const aggregatedRows = Object.values(aggregation).map(agg => {
		aggregateIndices.forEach(({ type, rename }) => {
			if (type === 'average') {
				agg.data[rename] /= agg.rowIds.length;
			}
			agg.row[newHeaders.indexOf(rename)] = agg.data[rename];
		});
		return { row: agg.row, rowIds: agg.rowIds };
	});

	return { headers: newHeaders, rows: aggregatedRows };
};

const performNestedAggregation = (table, tiers, originalRowIds, level = 0) => {
	if (level >= tiers.length) {
		return table.rows.map((_, index) => ({ rowIds: [originalRowIds[index]] }));
	}

	const tier = tiers[level];
	const aggregatedResult = performAggregation(table, tier.keyColumns, tier.aggregateColumns, originalRowIds);

	const more = aggregatedResult.rows.map(aggRow => {
		const nestedTable = {
			headers: table.headers,
			rows: aggRow.rowIds.map(rowId => table.rows[rowId])
		};
		const nestedRowIds = aggRow.rowIds;
		return performNestedAggregation(nestedTable, tiers, nestedRowIds, level + 1);
	});

	return { headers: aggregatedResult.headers, rows: aggregatedResult.rows.map(r => r.row), more };
};

export const aggregateTableDataWithTiers = (table, tiers) => {
	if (!table || !table.headers || !table.rows || table.rows.length === 0) {
		return { headers: [], rows: [], more: [] };
	}

	const augmentedTable = augmentTableWithDateInfo(table, tiers.flatMap(tier => tier.keyColumns.filter(col => col.type === 'date')));
	const originalRowIds = table.rows.map((_, index) => index);

	const result = performNestedAggregation(augmentedTable, tiers, originalRowIds);
	return { headers: result.headers, rows: result.rows, more: result.more };
};

export const calculateMaxMinValues = (data, scaleMode='global') => {
    if (!data || !data.rows || !data.headers || data.rows.length === 0) return {};
    let minValues = new Array(data.headers.length).fill(Infinity);
    let maxValues = new Array(data.headers.length).fill(-Infinity);

    if (scaleMode === 'global') {
        const allNumericValues = data.rows.flat().filter(value => !isNaN(value) && isFinite(value) && value !== null);
        if (allNumericValues.length > 0) {
            minValues = [Math.min(...allNumericValues)];
            maxValues = [Math.max(...allNumericValues)];
        } else {
            // Default values if no numeric values are present
            minValues = [0]; 
            maxValues = [1]; 
        }
    } else {
        data.headers.forEach((colHeader, colIndex) => {
            const numericColumnValues = data.rows
                .map(row => row[colIndex])
                .filter(value => !isNaN(value) && isFinite(value) && value !== null);

            if (numericColumnValues.length > 0) {
                minValues[colHeader] = Math.min(...numericColumnValues);
                maxValues[colHeader] = Math.max(...numericColumnValues);
            } else {
                // Default values if no numeric values in the column
                minValues[colHeader] = 0; 
                maxValues[colHeader] = 1; 
            }
        });
    }

    return { maxValues, minValues };
};


export const renameTableHeader = (table, oldHeader, newHeader) => {
    const newHeaders = table.headers.map(header => header === oldHeader ? newHeader : header);
    return { ...table, headers: newHeaders };
};

export const convertToMagnitude = (value, isInThousands = true) => {
    // Convert value to a number if it's a string. Handle non-numeric strings gracefully.
    const numericValue = Number(value);

    // Check if the conversion resulted in an invalid number
    if (isNaN(numericValue)) {
        return "N/A"; // Return "N/A" or any indication of an invalid number
    }

    // Early return if the value is exactly 0 to avoid unnecessary computation
    if (numericValue === 0) {
        return "0";
    }

    const isNegative = numericValue < 0;
    const absoluteValue = Math.abs(numericValue);

    const units = ['K', 'M', 'B', 'T'];
    let unitIndex = -1;
    let adjustedValue = isInThousands ? absoluteValue * 1000 : absoluteValue;

    while (adjustedValue >= 1000 && unitIndex < units.length - 1) {
        adjustedValue /= 1000;
        unitIndex++;
    }
    const isDecimalZero = adjustedValue % 1 === 0;

    // Format the number with or without the decimal part
    const formattedValue = isDecimalZero ? String(Math.floor(adjustedValue)) : adjustedValue.toFixed(1);

    // Prepend the negative sign if the original value was negative
    return `${isNegative ? '-' : ''}${formattedValue}${unitIndex >= 0 ? units[unitIndex] : ''}`;
};

export const sortTable = (tableData, sortingCriteria) => {
    const columnIndex = (name) => tableData.headers.indexOf(name);

    const sortedRows = [...tableData.rows].sort((a, b) => {
        for (let { column, order } of sortingCriteria) {
            const colIndex = columnIndex(column);
            if (a[colIndex] < b[colIndex]) return order === 'asc' ? -1 : 1;
            if (a[colIndex] > b[colIndex]) return order === 'asc' ? 1 : -1;
        }
        return 0;
    });
    const newTableData = { ...tableData, rows: sortedRows };
    return newTableData;
};

/*
export {
    filterTableByColumnValue,
    filterTableByColumnValues,
    aggregateTableData,
    processTable,
    dataFromColumns,
    dataFromRows,
    convertToMagnitude
};
*/
