import React, { useState, useEffect, useRef, memo } from 'react';
import './App.css';
import { usePubSub } from './PubSubContext'; // Assuming you have created a PubSubContext
import { convertToMagnitude } from './TableOperations';

const Table =  memo(({ rawData, largeSize = false, channels = [], keyColumn= '', initialDataKey='Dallas Cowboys', data={}, options={}, app={}, highlight={} }) => {
    const scaleMode = 'local'; // 'global' or 'local'
    const tableRef = useRef(null);
    const [maxColumnWidths, setMaxColumnWidths] = useState([]);
    const [historicalMin, setHistoricalMin] = useState([]);
    const [historicalMax, setHistoricalMax] = useState([]);
    const { fontSize = '1.0em' } = options || {};
    let width = app["width"];
    let height = app["height"]; 
    const [matchedRowsIndexes, setMatchedRowsIndexes] = useState([]);

    const columns_to_include = data["columns_to_use"];
    const { publish, subscribe } = usePubSub(); // Extract the publish function from the context
    //const { column, value } = highlightCriteria || {};
    console.log("highlight in table", highlight, rawData);
    useEffect(() => {
        console.log("in highlight use effect 9999", highlight);
        if (!rawData.rows || !rawData.headers) {
            return;  // Early return if 'rows' or 'headers' are missing
        }
        if (highlight.fields && highlight.values!== undefined && tableRef.current) {
            const newMatchedRowsIndexes = rawData.rows.reduce((acc, row, index) => {
                if (rawData.headers.includes(highlight.fields[0]) &&
                    highlight.values.includes(row[rawData.headers.indexOf(highlight.fields[0])])) {
                    acc.push(index);
                }
                return acc;
            }, []);

            console.log("checking highlight info", highlight.value, newMatchedRowsIndexes);
            setMatchedRowsIndexes(newMatchedRowsIndexes);
            if (newMatchedRowsIndexes.length > 0) {
                const row = tableRef.current.querySelector(`tr:nth-child(${newMatchedRowsIndexes[0] + 2})`);
                if (row) {
                    // Manually calculate and set scroll
                    const container = tableRef.current.parentElement;  // Assuming tableRef is the table and it's directly inside the scrollable container
                    const rowTop = row.offsetTop;
                    const rowHeight = row.offsetHeight;
                    const containerHeight = container.clientHeight;

                    // Scroll to the row so it's centered in the container
                    container.scrollTop = rowTop + rowHeight / 2 - containerHeight / 2;
                }
            }
        }
    }, [highlight]);

    console.log("after scrhool rows", matchedRowsIndexes);

    const transformData = (okdata, columnsToInclude) => {
        if (!okdata || !okdata.headers || !okdata.rows || okdata.rows.length === 0) {
            return { headers: [], rows: [] };
        }
    
        const isFloat = (str) => {
            return /^-?\d+\.\d+$/.test(str) || /^-?\d+$/.test(str);
        }
    
        // Destructuring to extract headers and rows
        const { headers, rows } = okdata;
        // Determine the columns to include
        const columns = columnsToInclude.length === 0 ? headers : columnsToInclude.filter(column => headers.includes(column));
        const columnIndex = columns.map(column => headers.indexOf(column)); // Get indexes of columns to include
    
        // Transform rows
        const transformedRows = rows.map(row => {
            return columnIndex.map(index => {
                const value = row[index]; // Get the value from the row based on the index
                return isFloat(value) ? parseFloat(value) : value;
            });
        });
        return { headers: columns, rows: transformedRows };
    };
    const transformedData = transformData(rawData, columns_to_include);
    //var data = transformedData;
        
    
    const updateMaxColumnWidths = () => {
        if (tableRef.current) {
            const columns = tableRef.current.querySelectorAll('thead th');
            const newMaxWidths = Array.from(columns).map((th, index) => {
                const style = window.getComputedStyle(th);
                const paddingLeft = parseInt(style.paddingLeft, 10);
                const paddingRight = parseInt(style.paddingRight, 10);
                const borderLeft = parseInt(style.borderLeftWidth, 10);
                const borderRight = parseInt(style.borderRightWidth, 10);

                const currentWidth = th.getBoundingClientRect().width - (paddingLeft + paddingRight + borderLeft + borderRight);
                const maxWidth = maxColumnWidths[index] || 0;
                return Math.max(currentWidth, maxWidth);
            });
            setMaxColumnWidths(newMaxWidths);
        }
    };

    const isColumnNumeric = (colIndex) => {
        var res  = transformedData.rows.every(row => !isNaN(parseFloat(row[colIndex])) && isFinite(row[colIndex]));
        return transformedData.rows.every(row => !isNaN(parseFloat(row[colIndex])) && isFinite(row[colIndex]));
    };

    function interpolateColorHelper(color1, color2, color3, factor) {
        let result;
    
        if (factor <= 0.5) {
            // Scale factor to be between 0 and 1 for the first interpolation
            let scaledFactor = factor * 2;
            result = color1.slice();
            for (let i = 0; i < 3; i++) {
                result[i] = Math.round(result[i] + scaledFactor * (color2[i] - color1[i]));
            }
        } else {
            // Scale factor to be between 0 and 1 for the second interpolation
            let scaledFactor = (factor - 0.5) * 2;
            result = color2.slice();
            for (let i = 0; i < 3; i++) {
                result[i] = Math.round(result[i] + scaledFactor * (color3[i] - color2[i]));
            }
        }
    
        return result;
    }

    function logScale(value, min, max) {
        // Adjust these parameters to control the scaling effect
        const scaleMin = 1;
        const scaleMax = 10;
    
        // Map value from [min, max] to [scaleMin, scaleMax]
        const scaledValue = scaleMin + (scaleMax - scaleMin) * (value - min) / (max - min);
    
        // Apply logarithmic scaling
        return Math.log(scaledValue) / Math.log(scaleMax);
    }
    
    function numberToColor(number, min, max) {
        const red = [255, 0, 0];
        const yellow = [255, 255, 0];
        const green = [0, 255, 0];
        //const factor = (number - min) / (max - min);
        const factor = logScale(number, min, max);
        return interpolateColor(red, yellow, green, factor);
    }

    const interpolateColor = (value, min, max, reversed=false) => {
        let factor = (value - min) / (max - min);
        if( reversed ) {
            factor = 1 - factor;
        }
        const color = interpolateColorHelper([255, 0, 0], [255, 255, 0], [0, 255, 0], factor);
        return color;
    };

    const getColor = (cell, colIndex, useHistorical, reversed) => {
        const min = useHistorical ? historicalMin[colIndex] : minValues[colIndex];
        const max = useHistorical ? historicalMax[colIndex] : maxValues[colIndex];
        //const factor = (cell - min) / (max - min);
        return interpolateColor(cell, min, max, reversed);
    };

    // Calculate min and max values for each column or globally
    const calculateMinMaxValues = () => {
        let minValues = new Array(transformedData.headers.length).fill(Infinity);
        let maxValues = new Array(transformedData.headers.length).fill(-Infinity);
    
        if (scaleMode === 'global') {
            const allNumericValues = transformedData.rows.flat().filter(value => !isNaN(value) && isFinite(value) && value !== null);
            if (allNumericValues.length > 0) {
                minValues = [Math.min(...allNumericValues)];
                maxValues = [Math.max(...allNumericValues)];
            } else {
                // Default values if no numeric values are present
                minValues = [0]; 
                maxValues = [1]; 
            }
        } else {
            transformedData.headers.forEach((_, colIndex) => {
                const numericColumnValues = transformedData.rows
                    .map(row => row[colIndex])
                    .filter(value => !isNaN(value) && isFinite(value) && value !== null);
    
                if (numericColumnValues.length > 0) {
                    minValues[colIndex] = Math.min(...numericColumnValues);
                    maxValues[colIndex] = Math.max(...numericColumnValues);
                } else {
                    // Default values if no numeric values in the column
                    minValues[colIndex] = 0; 
                    maxValues[colIndex] = 1; 
                }
            });
        }
    
        return { minValues, maxValues };
    };

    const { minValues, maxValues } = calculateMinMaxValues();

    const backgroundGradientFlags = () => {
        // Check if backgroundColors exists and use defaults if it does not
        const backgroundColors = options.backgroundColors || {};
    
        const defaultFlags = {
            gradient: backgroundColors.gradient || ["#ff0000", "#ffff00", "#00ff00"],
            globalMinMax: backgroundColors.globalMinMax || false,
            globalColumnMinMax: backgroundColors.globalColumnMinMax || false,
            disableGradient: backgroundColors.disableGradient || false,
            convertToMagnitude: false,
            inputValueInThousands: true,
        };
    
        let flagsByColumn = {};
    
        // Initialize default flags for each header
        columns_to_include.forEach(header => {
            flagsByColumn[header] = { ...defaultFlags, column: header };
        });
    
        // Check if columnConfigs exists before iterating
        if (backgroundColors.columnConfigs) {
            backgroundColors.columnConfigs.forEach(columnConfig => {
                flagsByColumn[columnConfig.column] = {
                    ...defaultFlags,
                    ...flagsByColumn[columnConfig.column],
                    ...columnConfig,
                };
            });
        }
    
        return flagsByColumn;
    };

    useEffect(() => {
        updateMaxColumnWidths();
    
        setHistoricalMin(prevMin => {
            if (!prevMin || prevMin.length === 0) {
                // Initial setting of historicalMin
                return minValues.map(value => isFinite(value) ? value : 0);
            } else {
                // Update existing historicalMin
                return prevMin.map((value, index) => isFinite(minValues[index]) ? Math.min(value, minValues[index]) : value);
            }
        });
    
        setHistoricalMax(prevMax => {
            if (!prevMax || prevMax.length === 0) {
                // Initial setting of historicalMax
                return maxValues.map(value => isFinite(value) ? value : 1);
            } else {
                // Update existing historicalMax
                return prevMax.map((value, index) => isFinite(maxValues[index]) ? Math.max(value, maxValues[index]) : value);
            }
        });
    }, [rawData, width, height]);



    if (transformedData == [] ||  !transformedData.headers) return <p>No data provided</p>;
    const headers = transformedData[0] ? Object.keys(transformedData[0]) : [];

    const handleRowMouseOver = (team) => {
        
        channels.forEach(channelConfig => {
            if (channelConfig.pubsub === 'publish') {
                publish(channelConfig.channel, { field: channelConfig.field, value: team });
            }
        });
    };

    const fontstyle = {
        fontSize: fontSize // Apply font size to each cell
    };
    

    console.log("highlight rows", matchedRowsIndexes);
    return (
        <div className={`table-container ${largeSize ? 'large' : ''}`}>
            {data.title && <h4 className="table-title">{data.title}</h4>}
            <table className="table" ref={tableRef} style={fontstyle}>
                <thead>
                    <tr>
                        {/* Iterate over headers for column titles */}
                        {transformedData.headers.map((header, idx) => (
                            <th key={idx} style={{ minWidth: maxColumnWidths[idx] + 'px' }}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {transformedData.rows.map((row, rowIndex) => {
                        const isHighlighted = matchedRowsIndexes.includes(rowIndex);  // Check if the current row is highlighted
                        const backgroundFlags = backgroundGradientFlags(); // Function call assuming it’s defined outside of this render block
    
                        return (
                            <tr key={rowIndex}
                                onMouseOver={() => handleRowMouseOver(row[transformedData.headers.indexOf('Team')])}
                                style={{
                                    outline: isHighlighted ? '2px solid red' : 'none', // Highlight by outline
                                    outlineOffset: '-2px'
                                }}>
                                {row.map((cell, colIndex) => {
                                    const columnFlags = backgroundFlags[transformedData.headers[colIndex]];
                                    let displayCell = cell; // Default to original cell
                                    if (cell != null && columnFlags.convertToMagnitude) {
                                        displayCell = convertToMagnitude(cell, columnFlags.inputValueInThousands);
                                    }
    
                                    if (cell != null && !columnFlags.disableGradient) {
                                        const useHistorical = columnFlags.globalColumnMinMax;
                                        const useReverseGradient = columnFlags.reverseGradient;    
                                        const rgbArray = getColor(cell, colIndex, useHistorical, useReverseGradient);
                                        const rgbString = `rgb(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]})`;
                                        return (
                                            <td key={colIndex} style={{ backgroundColor: rgbString }}>{displayCell}</td>
                                        );
                                    } else {
                                        return (
                                            <td key={colIndex}>{displayCell}</td>
                                        );
                                    }
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}, areEqual);

function areEqual(prevProps, nextProps) {
    return prevProps.rawData === nextProps.rawData &&
            prevProps.highlight === nextProps.highlight;
}

export default Table;
