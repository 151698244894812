import React, { useMemo } from 'react';
import { calculateMaxMinValues } from './TableOperations';

const normalize = (value, min, max) => {
	return ((value - min) / (max - min)) * 100;
};

const createAdata = (rawData, columnsToUse, minValues, maxValues, highlight) => {
	// Verify the required parts of rawData are available
	console.log("adata highlight",highlight);	
	if (!rawData || !rawData.headers || rawData.rows.length === 0 || !highlight.fields) return [];
    const highlightColumn = highlight.fields[0];
    const highlightRow = highlight.values[0];

	// Create a mapping of column names to their respective indices
	const columnIndexMap = {};
	rawData.headers.forEach((header, index) => {
		columnIndexMap[header] = index;
	});

	// Get the index of the highlight column
	const highlightColIndex = columnIndexMap[highlightColumn];
	if (typeof highlightColIndex === 'undefined') {
		console.warn(`Highlight column "${highlightColumn}" not found in headers.`);
		return []; // Return an empty array if highlight column is not found
	}

	// Find the row that matches the highlightRow value in the highlight column
	const targetRow = rawData.rows.find(row => row[highlightColIndex] === highlightRow);
	if (!targetRow) {
		console.warn(`No row found where "${highlightColumn}" matches "${highlightRow}".`);
		return []; // Return an empty array if no matching row is found
	}

	// Use the column names to retrieve and normalize the appropriate values
	const result = columnsToUse.reduce(
		(acc, colName) => {
			// Get the index corresponding to the column name
			const colIndex = columnIndexMap[colName];

			// If the index is undefined, skip this column (e.g., it might not exist in the headers)
			if (typeof colIndex === 'undefined') {
				console.warn(`Column "${colName}" not found in headers.`);
				acc.adata.push(0); // Return a default value for normalization or handle differently
				acc.originalValues.push(null); // Or choose a different default if needed
				return acc;
			}

			// Retrieve the value from the matching row
			const value = targetRow[colIndex];

			// Normalize the value using the min/max dictionaries and store both values
			acc.adata.push(normalize(value, minValues[colName], maxValues[colName]));
			acc.originalValues.push(value);

			return acc;
		},
		{ adata: [], originalValues: [] }
	);

	return result;
};


const RadarChart = ({ rawData,initialDataKey = 'Miami Dolphins', channels = [], data={}, app={}, highlight={}}) => {
    //const adata = [66, 43, 88, 54, 56];
    const { width, height } = app;

    const radius = Math.min(width, height) / 2; // Radius of the radar chart
    const angleSlice = (Math.PI * 2) / data.columns_to_use.length; // The angle between each axis
    
    const {maxValues, minValues} = calculateMaxMinValues(rawData, false);
    const adjusted_data = useMemo(() => {
		return createAdata(rawData, data.columns_to_use, minValues, maxValues, highlight);
	}, [rawData, data.columns_to_use, highlight]);
    const { adata = [], originalValues = [] } = adjusted_data;

    // Convert each data point to polar coordinates
    const points = adata.map((value, index) => {
        const angle = angleSlice * index - Math.PI / 2; // Adjust starting angle to have first axis along the vertical
        const r = (value / 100) * radius; // Scale radius with the value
        return {
            x: width / 2 + r * Math.cos(angle), // Convert polar to cartesian
            y: height / 2 + r * Math.sin(angle),
            value: value
        };
    });

    // Create the path string for the SVG polygon
    const pathData = points.map(point => `${point.x},${point.y}`).join(' ');

    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
            <circle cx={width / 2} cy={height / 2} r={radius} fill="none" stroke="#ccc" />
            {points.map((point, index) => (
                <g key={index}>
                    <line x1={width / 2} y1={height / 2} x2={point.x} y2={point.y} stroke="#ccc" />
                    <text x={point.x} y={point.y - 5} textAnchor="middle" fontSize="12px" fill="#333">
                        {data.columns_to_use[index]}: {originalValues[index]}
                    </text>
                </g>
            ))}
            <polygon points={pathData} fill="rgba(0, 123, 255, 0.6)" stroke="#007bff" strokeWidth="2" />
        </svg>
    );
};

export default RadarChart;