import React from 'react';
import { usePubSub } from './PubSubContext';

const Details = ({ rawData, app={}, channels=[], data={} }) => {
	// PubSub subscription setup
	const { subscribe } = usePubSub();

	// Assuming app.data.columnsToUse is an array of column names
	const indices = data?.columns_to_use?.map(column => 
		rawData?.headers?.indexOf(column) ?? -1
	);
	

	// Extract fieldData for each valid index if the index is non-negative
	const fieldDataItems = indices?.map(index => 
		index >= 0 ? rawData?.rows?.[0]?.[index] ?? '' : ''
	) || [];

	return (
		<div>
			<h2>Detail Information</h2>
			{fieldDataItems.map((item, index) => (
				<div key={index}>
					<p>Title: {item}</p>
				</div>
			))}
		</div>
	);
};

export default Details;