import Papa from 'papaparse';

async function loadCSVData(fileUrl) {
    try {
        const response = await fetch(fileUrl);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const csvText = await response.text();
        const parsed = Papa.parse(csvText, { header: true, dynamicTyping: true });

        const headers = parsed.meta.fields;
        const rows = parsed.data.map(row => 
            headers.map(header => row[header])
        );

        // Convert columns with all floats to float values
        const columns = headers.map((_, columnIndex) => 
            rows.map(row => row[columnIndex])
        );

        columns.forEach((column, index) => {
            if (column.every(item => typeof item === 'number' && !isNaN(item))) {
                rows.forEach(row => {
                    row[index] = parseFloat(row[index]);
                });
            }
        });

        return { "headers": headers, "rows": rows };
    } catch (error) {
        console.error("Error loading CSV:", error);
        throw error;
    }
}

function transformData(data, keyColumn, columnsToInclude) {
    if (!data || !Array.isArray(data.headers) || !Array.isArray(data.rows)) {
        throw new Error('Invalid data format. Expected format: {"headers": [..], "rows": [[..]]}');
    }

    const keyColumnIndex = data.headers.indexOf(keyColumn);
    if (keyColumnIndex === -1) {
        throw new Error(`Key column '${keyColumn}' not found in headers`);
    }

    // Filter out headers not in columnsToInclude and add the key column
    const filteredHeaders = data.headers.filter(header => 
        columnsToInclude.includes(header) || header === keyColumn
    );

    const resultRows = data.rows.map(row => {
        // Filter each row to include only the columns in columnsToInclude plus the key column
        return filteredHeaders.map(header => row[data.headers.indexOf(header)]);
    });

    return { headers: filteredHeaders, rows: resultRows };
}

export { loadCSVData, transformData };