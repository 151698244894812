import React, { useState, useEffect } from 'react';

const MemoryUsage = () => {
    const [memoryInfo, setMemoryInfo] = useState('Calculating...');

    useEffect(() => {
        function getMemoryUsage() {
            if (window.performance && window.performance.memory) {
                const usedMemory = performance.memory.usedJSHeapSize;
                const totalMemory = performance.memory.totalJSHeapSize;

                // Convert bytes to MB for easier reading
                const usedMB = (usedMemory / (1024 * 1024)).toFixed(2);
                const totalMB = (totalMemory / (1024 * 1024)).toFixed(2);

                return `${usedMB} MB of ${totalMB} MB`;
            } else {
                return "Not supported by this browser.";
            }
        }

        // Initial fetch of the memory data
        setMemoryInfo(getMemoryUsage());

        // Set an interval to update the memory info every 30 seconds
        const interval = setInterval(() => {
            setMemoryInfo(getMemoryUsage());
        }, 30000);  // 30 seconds = 30,000 milliseconds

        // Cleanup: clear the interval when the component is unmounted
        return () => clearInterval(interval);
    }, []);  // The empty dependency array means this effect will only run once, similar to `componentDidMount` in class components.

    return (
        <div>
            Memory used by this page: <span>{memoryInfo}</span>
        </div>
    );
};

export default MemoryUsage;