import React, { useState, useEffect } from 'react';
import { usePubSub } from './PubSubContext'; 

const DropdownSelector = ({ rawData,  app={}, channels=[], data={} }) => {
    const { publish } = usePubSub();
    const columnName = data["columnName"];
    const label = data["label"];
    const [options, setOptions] = useState(['No filter']);

    console.log("data", rawData);

    useEffect(() => {
        if (rawData && rawData.headers && rawData.rows) {
            const columnIndex = rawData.headers.indexOf(columnName);
            if (columnIndex !== -1) {
                const uniqueItems = new Set(rawData.rows.map(row => row[columnIndex]));
                const optionsArray = ['No filter', ...uniqueItems];
                setOptions(optionsArray);
            }
        }
    }, [rawData, columnName]);

    const [selectedItem, setSelectedItem] = useState('No filter');

    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedItem(newValue);
        /*
        channels.forEach(channelConfig => {
            if (channelConfig.pubsub === 'publish') {
                publish(channelConfig.channel, { field: channelConfig.field, value: newValue });
            }
        });
        */

        channels.forEach(channelConfig => {
            if (channelConfig.pubsub === 'publish') {
                publish(channelConfig.channel, [newValue]);
            }
        });
    };

    // Prevent event propagation on mouse down to avoid Rnd interpreting it as a move action
    const handleMouseDown = (event) => {
        event.stopPropagation();
    };

    return (
        <div onMouseDown={handleMouseDown}>
            <label htmlFor="dropdown-selector">{label} </label>
            <select id="dropdown-selector" value={selectedItem} onChange={handleChange} onMouseDown={handleMouseDown}>
                {options.map((item, index) => (
                    <option key={index} value={item}>{item}</option>
                ))}
            </select>
        </div>
    );
};

export default DropdownSelector;